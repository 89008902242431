import { NgModule, enableProdMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { Storage } from '@ionic/storage';
import { HTTP } from '@ionic-native/http/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { Globalization } from '@ionic-native/globalization/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

import { GooglePlus } from '@ionic-native/google-plus/ngx';
import { Facebook } from '@ionic-native/facebook/ngx';
import { SignInWithApple } from '@ionic-native/sign-in-with-apple/ngx';
import { AnimatedloadingComponent } from './component/animatedloading/animatedloading.component';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { CallNumber } from '@ionic-native/call-number/ngx';

import { FacebookLoginProvider, GoogleLoginProvider, SocialLoginModule, SocialAuthServiceConfig } from "angularx-social-login"
import { DatePipe } from '@angular/common';

//For One Signal Fix
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

//Production Build Bug Fix
import { PipeModule } from './core/pipe.module';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, environment.API_URL + 'language?lang=', '');
}

//enableProdMode();

@NgModule({
  declarations: [AppComponent, AnimatedloadingComponent],
  entryComponents: [],
  imports: [
    HttpClientModule,
    BrowserModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),

    IonicModule.forRoot({
      rippleEffect: false,
      mode: 'md'
    }),
    AppRoutingModule,
    DragDropModule,
    SocialLoginModule,
    PipeModule
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    Storage,
    HTTP,
    Geolocation,
    OneSignal,
    Globalization,
    InAppBrowser,
    GooglePlus,
    Facebook,
    SignInWithApple,
    CallNumber,
    SplashScreen,
    StatusBar,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '774777851007-7ca5gut2i0neofn52p9p3a6kjl5pg8gq.apps.googleusercontent.com'
            )
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('376199447267619')
          }
        ]
      } as SocialAuthServiceConfig,
    },
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
