import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { AppServiceService } from './api/app-service.service';
import { CallNumber } from '@ionic-native/call-number/ngx';

//For One Signal Fix
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { RequestService } from 'src/app/core/request.service';

declare var config: any;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  public viewType: any;

  constructor(
    public appService: AppServiceService,
    private platform: Platform,
    private callNumber: CallNumber,
    public splashScreen: SplashScreen,
    public statusBar: StatusBar,
    public oneSignal: OneSignal,
    public requestService: RequestService,
  ) {
    //For One Signal Fix
    this.initializeApp();
    this.appService.appInit();
  }

  async initializeApp() {
    this.platform.ready().then(() => {

      this.statusBar.overlaysWebView(true);
      this.splashScreen.hide();

      this.requestService.post('onesignal/key?device_type=' + config.plf, []).then(data => {
        if (data.status) {

          //set one signal id for web to init
          config.osid = data.onesignal_key;
          this.appService.appObject.oneSignalConfig.key = data.onesignal_key;
          this.appService.appObject.oneSignalConfig.fireid = data.firebase_id;

          //For One Signal Fix
          if ((this.platform.is('android') || this.platform.is('ios')) && !this.appService.isMobileWeb()) {
            this.oneSignal.startInit(this.appService.appObject.oneSignalConfig.key, this.appService.appObject.oneSignalConfig.fireid);
            this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.None);
            this.oneSignal.handleNotificationReceived().subscribe(data => {
              // do something when notification is received 
            });
            this.oneSignal.handleNotificationOpened().subscribe(data => {
              // do something when a notification is opened
            });
            this.oneSignal.endInit();

            this.oneSignal.getIds().then(identity => {
              //this.appService.showAlert('success','success',JSON.stringify(identity));
              this.appService.appObject.deviceUUID = identity.userId;
              //call device registration again to store device id which from one signal
              this.appService.deviceRegister();
              this.appService.updateAppObject();
            }, err => {
              //this.appService.showAlert('error','error',err);
            });

          }

        }
      });

    });
  }

  call() {
    this.callNumber.callNumber(this.appService.appObject.selectedRestaurantDetails.owner_mobile_number, true)
      .then(res => console.log('Launched dialer!', res))
      .catch(err => console.log('Error launching dialer', err));
  }

}
