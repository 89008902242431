import { Injectable, NgZone, EventEmitter } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from '../core/storage.service';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { RequestService } from '../core/request.service';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { MenuController, ModalController, NavController, Platform, ToastController } from '@ionic/angular';
import { Globalization } from '@ionic-native/globalization/ngx';
import { LangPage } from '../views/modals/lang/lang.page';
import { environment } from 'src/environments/environment';
import { InAppBrowser, InAppBrowserOptions } from '@ionic-native/in-app-browser/ngx';
import { ToppingsPage } from '../views/modals/toppings/toppings.page';
import { CartPage } from '../views/modals/cart/cart.page';
import { CrossSellingPage } from '../views/modals/cross-selling/cross-selling.page';
import { AlertPage } from '../views/modals/alert/alert.page';
import { ModalPopoverPage } from '../views/web-views/shared/modal-popover/modal-popover.page';
import consts from '../views/web-views/shared/constatnts.json';
import { Subject, BehaviorSubject, Observable } from 'rxjs';


declare var google: any;
declare var config: any;
declare var PlatformReady: any;


@Injectable({
  providedIn: 'root'
})

export class AppServiceService {
  POPOVER_TYPES = consts.POPOVER_TYPES;


  //App Object

  animationDrop: boolean = false;
  className: string = '';
  className2: string = '';

  AddNsUB: any = [];

  STORAGE_KEYS = {
    PAGES_KEY: "APP_PAGES"
  };
  googleAutocomplete: any;
  currentLang: string = 'en';
  cartIsOpen: boolean = false;
  editing_cart_item: any = null;

  routeToRestaurantList: boolean = false;
  activeMenu = null;
  menuList: any = [];
  dishList: any = [];
  hideCartButton: boolean = true;
  selectedDish: any = {
    total: 0,
    toping_total: 0,
    size_index: 0,
    size_id: null,
    selectedTopings: [],
    fullDishInfo: []
  };

  isSideMenu: boolean = false;
  isFooterMenu: boolean = false;
  closenotice: boolean = false;
  isPreOrderAccept: boolean = false;
  close_note_toggle: boolean = true;

  public appObject: any = {
    appVersion: '0.1',
    isFirstTime: true,
    isAuthed: false,
    platformIsReady: false,
    viewPlatform: '',
    isLoading: true,
    currentLang: '',
    isAddressHave: false,
    isOrderProcess: false,
    sideMenuPages: [],
    FooterCouponLink: null,
    myAddress: {
      name: '',
      lat: '',
      lng: ''
    },
    oneSignalConfig: {
      key: '',
      fireid: ''
    },
    deviceUUID: '',
    userId: '',
    user: {
      type: 'guest',
      fname: '',
      lname: '',
      email: '',
      telephone: '',
      backyard: '',
      instructions: ''
    },
    baseUrl: '',
    promotions: [],
    new_promotions: [],
    settings: [],
    errors: [],
    restaurants: [],
    langList: [],
    selectedRestaurant: null,
    selectedRestaurantDetails: [],
    selectedRestaurantId: null,
    selectedDeliveryMethod: 'delivery', //delivery, dine_in, pickup
    selectedCoupon: [],
    orderObjectDup: {
      cart: []
    },
    orderObject: {
      cart: [],
      discounts: [],
      order_tax: [],
      delivery_tax: [],
      cart_summery: {
        total_tax_inclusive: 0,
        total_tax_exclusive: 0,
        net_total_without_tax: 0,
        net_total: 0,
        total_discount: 0,
        total_with_discount_price: 0,
        delivery_cost: 0,
        gross_total: 0,
        min_reached: false,
        min_amount: 0,
        remarks: '',
        total_dish_count: 0
      },
      order_details: {
        delivery_type: '',
        delivery_date: 'asap',
        delivery_time: 'asap',
        delivery_address: {
          name: '',
          lat: '',
          lng: ''
        },
        payment_type: '',
        name: '',
        email: '',
        mobile_number: '',
        backyard: '',
        special_note: ''

      },
      resturent_id: "",
      device_id: "",
      device_type: "",
      lang: ""
    }
  };
  orderList: [];
  public email_content = true;
  public code_content = false;
  public repeatpass_content = false;

  public isWebVersion = false;
  private addToCartSuccessfulyCallBack = new Subject<any>();

  constructor(
    private storage: StorageService,
    private translate: TranslateService,
    private router: Router,
    private geolocation: Geolocation,
    private requestService: RequestService,
    private oneSignal: OneSignal,
    private platform: Platform,
    private globalization: Globalization,
    private modalController: ModalController,
    private iab: InAppBrowser,
    private menu: MenuController,
    private navController: NavController,
    public toastController: ToastController,
    public zone: NgZone
  ) {


  }


  //START INIT

  async appInit() {

    this.appObject.isLoading = true;
    //get App Object from storage if available else set object
    let appObject = await this.storage.get('appObject');

    //if app version is different force init
    this.appObject.deviceUUID = config.did;
    this.appObject.currentLang = environment.DEFAULT_LANG;
    if (appObject && this.appObject.appVersion == environment.APP_VERSION) {
      this.appObject = appObject;
      this.appObject.platformIsReady = false;
      this.appObject.isLoading = true;
      this.appObject.errors = [];
    } else {
      this.appObject.appVersion = environment.APP_VERSION;
      this.appObject.baseUrl = environment.BASE_URL;
      //set language
      await this.getLanguages();
    }

    await this.setDefaultLanguage();
    //register device in server
    await this.deviceRegister();

    //get google key
    await this.getGoogleKey();
    //get one signal key
    //await this.getOneSignalKey();
    //init one signal
    //await this.initOneSignal();

    //settigns
    await this.getSettings();

    this.appObject.platformIsReady = true;
    this.appObject.isLoading = false;
    //set global to ready
    config.platformReady = true;
    //clear cart for now
    //this.appObject.orderObject.cart = [];
    //this.appObject.orderObjectDup.cart = [];

    if (!appObject) {
      await this.updateAppObject();
      //should route to getting started
      //this.navController.navigateRoot(['/getting-started'], { replaceUrl: true });
    }
  }

  /**
   * This function for set default language as device language, function will map default language with
   * our avaiable language if user doesn't set manually in first time
  */
  async setDefaultLanguage() {

    if (this.appObject.currentLang != '') {
      this.translate.setDefaultLang(this.appObject.currentLang);
      this.translate.use(this.appObject.currentLang);
    } else {
      if ((this.platform.is('android') || this.platform.is('ios')) && !this.isMobileWeb()) {
        this.appObject.currentLang = environment.DEFAULT_LANG;
        let DeviceLang = await this.globalization.getPreferredLanguage();
        var patde = new RegExp("de");
        var patsi = new RegExp("si");
        if (this.appObject.langList.length > 0) {
          for (let i = 0; i < this.appObject.langList.length; i++) {
            let pat = new RegExp(this.appObject.langList[i]['code']);
            if (pat.test(DeviceLang.value)) {
              this.appObject.currentLang = this.appObject.langList[i]['code'];
            }
          }
        }
        this.translate.setDefaultLang(this.appObject.currentLang);
        this.translate.use(this.appObject.currentLang);
      } else {
        this.translate.setDefaultLang(environment.DEFAULT_LANG);
        this.translate.use(environment.DEFAULT_LANG);
        this.appObject.currentLang = environment.DEFAULT_LANG;
      }

    }

  }

  /***
   * Device register in server, this device id required for every api calls in future
  */
  async deviceRegister() {

    return await this.requestService.post('register', {
      'device_id': this.appObject.deviceUUID,
      'lang': this.appObject.currentLang,
      'type': this.appObject.user.type
    }).then(data => {
      if (data.status) {
        this.appObject.userId = data.data.id;
        this.gettingStarted();
      }
    }, error => {
      this.appObject.isLoading = false;
    });

  }

  /**
   * Get google key from server side
  */
  async getGoogleKey() {

    return await this.requestService.post('google/map-key?device_type=' + config.plf, []).then(data => {

      if (data.status) {
        config.gkey = data.key;
      }
    }, error => {
      this.appObject.isLoading = false;
    });

  }

  /**
   * Get one signal key from server before initialize push notifications
  */
  async getOneSignalKey() {

    return await this.requestService.post('onesignal/key?device_type=' + config.plf, []).then(data => {

      if (data.status) {
        //set one signal id for web to init
        config.osid = data.onesignal_key;

        this.appObject.oneSignalConfig.key = data.onesignal_key;
        this.appObject.oneSignalConfig.fireid = data.firebase_id;

      }
    }, error => {
      this.appObject.isLoading = false;
    });

  }

  /**
   *Initialize one signal push notifications
  */
  async initOneSignal() {

    if (this.platform.is('android') || this.platform.is('ios')) {
      window["plugins"].oneSignal.startInit(this.appObject.oneSignalConfig.key, this.appObject.oneSignalConfig.fireid);

      window["plugins"].oneSignal.inFocusDisplaying(window["plugins"].oneSignal.OSInFocusDisplayOption.InAppAlert);

      window["plugins"].oneSignal.handleNotificationReceived().subscribe(() => {
        // do something when notification is received
      });

      window["plugins"].oneSignal.handleNotificationOpened().subscribe(() => {
        // do something when a notification is opened
      });

      window["plugins"].oneSignal.endInit();
      window["plugins"].oneSignal.getIds().then(identity => {
        this.appObject.deviceUUID = identity.userId;
        //call device registration again to store device id which from one signal
        this.deviceRegister();
        this.updateAppObject();
      });

    }

  }

  /**
   * Update app object to store appObject
  */
  async updateAppObject() {

    return await this.storage.set('appObject', this.appObject);

  }

  //END INIT


  /**
   * Getting started update
  */
  async gettingStarted() {

    this.appObject.isFirstTime = false;
    await this.updateAppObject();
    this.router.navigateByUrl('home');
  }

  /**
 * Init google map
*/
  async initGoogle(googleaddress) {
    try {

      var options = {
        componentRestrictions: { country: environment.GOOGLE_COUNTRIES }
      };

      this.googleAutocomplete = new google.maps.places.Autocomplete(await googleaddress.getInputElement(), options);

      this.googleAutocomplete.addListener('place_changed', () => {



        var place = this.googleAutocomplete.getPlace();
        let addressIsValid = [];
        let address = '';
        //for testing only
        //addressIsValid['street_number'] = true;
        //addressIsValid['route'] = true;
        //addressIsValid['postal_code'] = true;
        //addressIsValid['postal_code'] = true;
        //should remove in production

        if (place.address_components) {
          addressIsValid = this.validateAddressComponents(place.address_components);
          // place.address_components.forEach(part => {

          //   // console.log(part);

          //   if (part.types[0]) {
          //     if (part.types[0] === "street_number") {
          //       addressIsValid['street_number'] = true;
          //     }
          //     if (part.types[0] === "route") {
          //       addressIsValid['route'] = true;
          //     }
          //     if (part.types[0] === "postal_code") {
          //       addressIsValid['postal_code'] = true;
          //     }
          //     if (part.types[0] === "locality" || (part.types[1] && part.types[1] === "political")) {
          //       addressIsValid['locality'] = true;
          //     }

          //   }
          // });


        }

        // console.log(place, addressIsValid);

        this.zone.run(() => {
          if (this.isAddressComponentValid(addressIsValid)) {

            this.appObject.isAddressHave = true;
            let address_splited = place.formatted_address.split(',');
            address = place.formatted_address;
            let country = address_splited[address_splited.length - 1];
            var changed = false;

            if (country.trim() === 'Germany') {
              address = address.replace(", Germany", "");
            }

            this.appObject.myAddress.name = address;
            // console.log(this.appObject.myAddress.name);
            this.appObject.myAddress.lat = place.geometry.location.lat();
            this.appObject.myAddress.lng = place.geometry.location.lng();
            this.updateAppObject();
            this.appObject.errors = [];
          } else {
            //address not valid
            this.appObject.isAddressHave = false;
            this.appObject.myAddress.name = '';
            this.appObject.myAddress.lat = '';
            this.appObject.myAddress.lng = '';


            this.updateAppObject();
            this.appObject.errors['address'] = ['address_is_not_valid'];
            // console.log(this.appObject);
          }

        });

      });
    } catch (error) {
      setTimeout(() => {
        this.initGoogle(googleaddress);
      }, 1000);
    }


  }

  /**
   * 
   * @param addressIsValid validated Address component array
   * @returns valid or not (boolean)
   */
  public isAddressComponentValid(addressIsValid) {
    return addressIsValid['street_number'] === true && addressIsValid['route'] === true && addressIsValid['postal_code'] === true && addressIsValid['locality'] === true
  }

  /**
   * Will validate address component object of google response from google autocomplete 
   * @param addressComponents address component object returned from google api
   * @returns validated address array
   */
  public validateAddressComponents(addressComponents) {
    let addressIsValid = [];
    addressComponents.forEach(part => {

      // console.log(part);

      if (part.types[0]) {
        if (part.types[0] === "street_number") {
          addressIsValid['street_number'] = true;
        }
        if (part.types[0] === "route") {
          addressIsValid['route'] = true;
        }
        if (part.types[0] === "postal_code") {
          addressIsValid['postal_code'] = true;
        }
        if (part.types[0] === "locality" || (part.types[1] && part.types[1] === "political")) {
          addressIsValid['locality'] = true;
        }

      }
    });
    return addressIsValid;
  }

  /**
   * Find restaurant near by address
  */
  async findNearByRestaurants(pageToGo: any = 'restaurant-list', getAllRestaurants: boolean = false) {
    this.appObject.isLoading = true;
    var sending;
    if (this.appObject.myAddress.lat == '' || this.appObject.myAddress.lng == '' || getAllRestaurants) {
      sending = {
        'device_id': this.appObject.deviceUUID,
        'lang': this.appObject.currentLang,
        'delivery_method': this.appObject.selectedDeliveryMethod
      };
    } else {
      if (this.appObject.selectedDeliveryMethod == 'delivery') {
        sending = {
          'device_id': this.appObject.deviceUUID,
          'lang': this.appObject.currentLang,
          'latitude': this.appObject.myAddress.lat,
          'longitude': this.appObject.myAddress.lng,
          'delivery_method': this.appObject.selectedDeliveryMethod
        };
      } else {
        sending = {
          'device_id': this.appObject.deviceUUID,
          'lang': this.appObject.currentLang,
          'delivery_method': this.appObject.selectedDeliveryMethod
        };
      }

    }
    return await this.requestService.post('restaurant/list', sending).then(data => {
      if (data.status) {
        this.appObject.restaurants = data.data;
        if (pageToGo == 'restaurant-list') {
          this.routeToRestaurantList = false;

        }
        pageToGo ? this.router.navigate([pageToGo], { replaceUrl: true }) : '';

      } else {
        this.appObject.restaurants = [];
        this.updateAppObject();
        this.router.navigate(['restaurant-list'], { replaceUrl: true });

      }
      this.appObject.isLoading = false;
    }, error => {
      this.appObject.isLoading = false;
    });

  }

  /**
   * Get current location of user
   */

  async getCurrentLocation() {

    this.appObject.isLoading = true;
    let options = { maximumAge: 60000, timeout: 30000, enableHighAccuracy: true };

    var cpos = "";
    try {
      let Glocation = await this.geolocation.getCurrentPosition(options);

      this.appObject.myAddress.lat = Glocation['coords']['latitude'];
      this.appObject.myAddress.lng = Glocation['coords']['longitude'];

      var postal = "";
      var city = "";
      var road = "";
      var homeno = "";

      var geocoder = new google.maps.Geocoder();
      var latlng = { lat: this.appObject.myAddress.lat, lng: this.appObject.myAddress.lng };
      geocoder.geocode({ 'location': latlng }, function (results, status) {
        if (status == google.maps.GeocoderStatus.OK) {

          for (let i = 0; i < results[0]['address_components'].length; i++) {
            if (results[0]['address_components'][i]['types'][0] == "postal_code") {
              postal = results[0]['address_components'][i]['short_name'];
            }
            if (results[0]['address_components'][i]['types'][0] == "locality") {
              city = results[0]['address_components'][i]['short_name'];
            }
            if (results[0]['address_components'][i]['types'][0] == "route") {
              road = results[0]['address_components'][i]['short_name'];
            }
            if (results[0]['address_components'][i]['types'][0] == "street_number") {
              homeno = results[0]['address_components'][i]['short_name'];
            }
          }

          cpos = cpos + (road != "" ? road + ", " : "");
          cpos = cpos + (homeno != "" ? homeno + ", " : "");
          cpos = cpos + (postal != "" ? postal + ", " : "");
          cpos = cpos + (city != "" ? city : "");


        }

      });


    } catch (error) {

    }

    setInterval(() => {
      if (cpos != "") {
        this.appObject.myAddress.name = cpos;
        this.appObject.isAddressHave = true;
        this.updateAppObject();
        cpos = "";
        this.appObject.isLoading = false;
        this.appObject.errors = [];

      }
    }, 1000);
  }

  /**
   * Get promotions
  */
  async getPromo() {

    return await this.requestService.post('promotion/list', {
      'device_id': this.appObject.deviceUUID,
      'lang': this.appObject.currentLang
    }).then(data => {
      if (data.status) {
        this.appObject.promotions = data.data;

        this.appObject.FooterCouponLink = '/my-products/' + this.appObject?.promotions?.resturent?.show_on_list[0]?.slug + '/category/' + this.appObject?.promotions?.category?.show_on_list[0]?.id + '/' + this.appObject?.promotions?.category?.show_on_list[0]?.name;

      }
    }, error => {
      this.appObject.isLoading = false;
    });

  }

  /**
   * Show languages
  */
  async showLanguage() {
    const modal = await this.modalController.create({
      component: LangPage,
      cssClass: 'custom-popup'
    });

    await modal.present();
  }


  /**
   * Login
  */
  async login(login_details: any) {

    this.appObject.errors = [];
    login_details['lang'] = this.appObject.currentLang;
    login_details['device_id'] = this.appObject.deviceUUID;
    login_details['device_type'] = config.plf;


    return new Promise((resolve, reject) => {
      this.requestService.post('login', login_details).then(response => {
        if (response.status) {

          this.appObject.userId = response.data.id;
          this.appObject.isAuthed = true;
          this.appObject.deviceUUID = response.data.device_id;
          this.appObject.user.type = 'registered';

          // this.navigateToRegisterd();
        } else {
          this.appObject.errors = this.getErrorArray(response.msg);
        }
        return resolve(response);
      }, error => {
        this.appObject.isLoading = false;
        return reject(error);
      });
    })

  }

  /**
   * Logout
   * @param isWebVersion send a boolean to check web or app version
  */
  async logOut(isWebVersion: boolean = false) {
    return new Promise(async (resolve, reject) => {
      await this.requestService.post('logout', {
        'device_id': this.appObject.deviceUUID,
        'language': this.appObject.currentLang,
        'device_type': config.plf
      }).then(async (response) => {
        // console.log('logout response : ', response);
        if (response.status) {
          this.appObject.isAuthed = false;
          // reset user object to guest
          this.appObject.user.type = 'guest';
          this.appObject.user.backyard = "";
          this.appObject.user.email = "";
          this.appObject.user.fname = "";
          this.appObject.user.instructions = "";
          this.appObject.user.lname = "";
          this.appObject.user.telephone = "";

          this.appObject.userId = '';
          await this.deviceRegister();
          await this.updateAppObject();

          // only redirect in mobile app
          if (!isWebVersion) this.router.navigate(['/home'], { replaceUrl: true });

          return resolve(response);

        }
      }).catch((error) => {
        // console.log('error :  ', error);
        return reject(error);
      })
    })
  }


  /**
   * Register
  */

  async register(registration_details: any) {

    return new Promise((resolve, reject) => {
      this.appObject.errors = [];
      registration_details['lang'] = this.appObject.currentLang;
      registration_details['device_id'] = this.appObject.deviceUUID;
      this.requestService.post("register", registration_details).then(data => {
        if (data.status) {

          this.appObject.isAuthed = true;
          this.appObject.userId = data.data.id;
          this.appObject.deviceUUID = data.data.device_id;
          this.appObject.user.type = 'registered';
          if (!this.isWebVersion) this.navigateToRegisterd();

        } else {
          this.appObject.errors = this.getErrorArray(data.msg);
        }
        // console.log('registration_details : ', registration_details);
        if (registration_details.type === 'facebook' || registration_details.type === 'google') {
          // console.log('Triggered');
          return resolve(data);
        }
        // console.log(this.appObject.errors);
      }, error => {
        this.appObject.isLoading = false;
      });
    });

  }


  async register_from_email(registration_details: any, isWebVersion: boolean = false) {

    this.appObject.isLoading = true;
    this.appObject.errors = [];
    registration_details['lang'] = this.appObject.currentLang;
    registration_details['device_id'] = this.appObject.deviceUUID;
    return await this.requestService.post("register", registration_details).then(data => {
      if (data.status) {

        this.appObject.isAuthed = true;
        this.appObject.userId = data.data.id;
        this.appObject.deviceUUID = data.data.device_id;
        this.appObject.user.type = 'registered';
        if (!isWebVersion) this.navigateToRegisterd();

      } else {
        this.appObject.errors = this.getErrorArray(data.msg);
      }

      this.appObject.isLoading = false;

    }, error => {
      this.appObject.isLoading = false;
    });

  }

  async navigateToRegisterd() {

    await this.updateAppObject();
    if (this.appObject.isOrderProcess) {
      this.router.navigate(['checkout']);
    } else {
      this.router.navigate(['profile']);

    }

  }


  getErrorArray(data) {
    let err = new Object();
    Object.keys(data).map(function (k) {

      if (typeof data[k][0] === 'object') {
        let e = new Object();
        let i = 0;
        Object.keys(data[k][0]).map(function (ke) {
          e[i] = data[k][0][ke];
          i++;
        });

        err[k] = e;

      } else {
        err[k] = { 0: data[k][0] };
      }

    });



    return err;

  }

  /**
  * Clear address
 */

  async clearAddress() {
    this.appObject.isAddressHave = false;
    this.appObject.myAddress.name = "";
    this.appObject.myAddress.lat = "";
    this.appObject.myAddress.lng = "";
    await this.updateAppObject();
    this.appObject.errors = {
      address: {
        0: 'address_is_required'
      }
    };
  }

  async clearCart() {
    this.appObject.orderObjectDup.cart = [];
    this.appObject.orderObject.cart = [];
    this.appObject.orderObject.cart_summery = {
      // cart_summery: {
      total_tax_inclusive: 0,
      total_tax_exclusive: 0,
      net_total_without_tax: 0,
      net_total: 0,
      total_discount: 0,
      total_with_discount_price: 0,
      delivery_cost: 0,
      gross_total: 0,
      min_reached: false,
      min_amount: 0,
      remarks: '',
      total_dish_count: 0
      //  }
    };

  }

  /**
  * Get restaurant by slug
 */

  async getRestaurantBySlug(slug, cat = null) {

    this.appObject.isLoading = true;
    if (this.appObject.deviceUUID == "") {
      setTimeout(() => {
        this.getRestaurantBySlug(slug);
      }, 100);
    } else {
      await this.requestService.post('restaurant/' + slug, {
        'device_id': this.appObject.deviceUUID,
        'language': this.appObject.currentLang,
        'device_type': config.plf,
        'delivery_type': this.appObject.selectedDeliveryMethod,
        'lat': this.appObject.myAddress.lat,
        'lng': this.appObject.myAddress.lng
      }).then(data => {
        if (data.status) {

          if (this.appObject.selectedRestaurantId != data.data.id) {
            this.clearCart();
            // console.log('restaurant not equal');
          }

          this.appObject.selectedRestaurant = slug;
          let payment_methods = JSON.parse(data.data['payment_methods']);
          this.appObject.selectedRestaurantDetails = data.data;
          this.appObject.selectedRestaurantDetails['payment_methods'] = [];
          payment_methods.forEach(element => {

            if (element.isActive == "true") {

              this.appObject.selectedRestaurantDetails['payment_methods'].push({
                displayName: element.displayName,
                id: element.id,
                isActive: element.isActive
              });

            }

          });

          if (this.appObject.selectedRestaurantDetails.openDetails.current_status == "closed" && !this.appObject.selectedRestaurantDetails.openDetails.is_pre_order) {
            this.closenotice = true;
          } else if (this.appObject.selectedRestaurantDetails.openDetails.current_status == "closed" && this.appObject.selectedRestaurantDetails.openDetails.is_pre_order) {
            this.isPreOrderAccept = true;
            this.closenotice = true;
          } else {
            this.closenotice = false;
          }

          this.appObject.selectedRestaurantId = data.data.id;
          this.updateAppObject();
          this.getMenuDetails(cat);
        }
        this.appObject.isLoading = false;
      }, error => {
        this.appObject.isLoading = false;
      });
    }

  }

  async getCouponPormo(slug) {
    return await this.requestService.post('restaurant/coupon-list/' + slug, {
      'device_id': this.appObject.deviceUUID,
      'language': this.appObject.currentLang,
      'device_type': config.plf,
    });
  }


  /**
  * Get menu details by restaurant_id
 */

  async getMenuDetails(cat = null) {
    this.requestService.post('menu-category/list', {
      'device_id': this.appObject.deviceUUID,
      'language': this.appObject.currentLang,
      'device_type': config.plf,
      'restaurant_id': this.appObject.selectedRestaurantId,
      'is_dish': 'true'
    }).then(data => {
      if (data.status) {
        this.menuList = data.data;
        if (this.menuList.length > 0) {
          if (cat == null) {
            this.getDishDetails(this.menuList[0].id, this.menuList[0].restaurants_id);
          } else {
            this.getDishDetails(cat, cat.restaurants_id);
          }

        }
      }
    }, error => {
      //this.appObject.isLoading = false;
    });
  }

  async getDishDetails(cat_id, res_id) {

    //this.appObject.isLoading = true;
    this.activeMenu = cat_id;
    this.requestService.post('dish/list', {
      'device_id': this.appObject.deviceUUID,
      'language': this.appObject.currentLang,
      'device_type': config.plf,
      'menu_categorie_id': cat_id,
      'restaurant_id': 2
    }).then(data => {
      if (data.status) {
        this.dishList = data.data;
      }
      this.appObject.isLoading = false;
    }, error => {
      this.appObject.isLoading = false;
    });
  }

  async getPriceLabel(price) {

    let p = '';
    if (this.appObject.settings.currency_position == 'after') {
      p = price + ' ' + this.appObject.settings.currency_symbol;
    } else {
      p = this.appObject.settings.currency_symbol + ' ' + price;
    }
    // console.log(p);
    return p;
  }

  async goToOrder() {
    if (this.appObject.isAddressHave) {
      this.router.navigate(['/restaurant-list'], { replaceUrl: false });
    } else {
      this.routeToRestaurantList = true;
      this.router.navigate(['/find-near-by-me'], { replaceUrl: true });
    }
  }

  async calcSelectedDishTotal() {

    if (this.selectedDish.fullDishInfo.is_size == 'true') {

      this.selectedDish.total = this.selectedDish.fullDishInfo.sizes[this.selectedDish.size_index][this.appObject.selectedDeliveryMethod];

    } else {

      this.selectedDish.total = this.selectedDish.fullDishInfo[this.appObject.selectedDeliveryMethod];

    }

    this.selectedDish.toping_total = 0;

    if (this.selectedDish.selectedTopings.length > 0) {
      for (let index = 0; index < this.selectedDish.selectedTopings.length; index++) {

        this.selectedDish.total += this.selectedDish.selectedTopings[index].total;
        this.selectedDish.toping_total += this.selectedDish.selectedTopings[index].total;
      }
    }

    // // console.log(this.selectedDish);
  }

  check_is_selected(t_id, is_mand, type) {
    let available = 0;
    this.selectedDish.selectedTopings.forEach(element => {
      if (t_id == element.topping_id) {
        available = element.count;
      }
    });

    if (available == 0 && is_mand == 'true' && type == "add-on") {
      this.appObject.errors['shoud_select_toping_' + t_id] = true;
      this.appObject.errors['mandatory_toping_should_be_selected'] = true;

    }
    return available;
  }

  async addSizeToCart(fromCrossSelling: boolean = false) {
    this.appObject.errors = [];
    // show alert when store is closed and preorder not accept
    if (this.closenotice && !this.isPreOrderAccept) {
      this.showAlert('error', 'key.restaurant_closed',
        this.appObject.selectedRestaurantDetails.openDetails.text ? this.appObject.selectedRestaurantDetails.openDetails.text : 'closeNote',
        false);
      return;
    }

    // console.log(this.selectedDish.fullDishInfo);
    //check total toping count if is_mandatory
    let error_on_dish = false;
    let selected_size_or_scenario = [];
    if (this.selectedDish.fullDishInfo.is_size == 'true') {
      selected_size_or_scenario = this.selectedDish.fullDishInfo.sizes[this.selectedDish.size_index].scenarios;

    } else {
      selected_size_or_scenario = this.selectedDish.fullDishInfo.scenarios;


    }

    //check mandatory toping is selected
    /*for (let index = 0; index < selected_size_or_scenario.length; index++) {
      if(selected_size_or_scenario[index].is_mandatory == 'true'){
        this.check_is_selected(selected_size_or_scenario[index].id);
      }
    }*/

    if (selected_size_or_scenario) {
      try {
        //scenario
        selected_size_or_scenario.forEach(element => {

          let this_scenario_selected_count = 0;
          //toping
          element['topings'].forEach(elementt => {
            let selected_count = this.check_is_selected(elementt.id, elementt.is_mandatory, element.btn_type);
            // // console.log('zzz:', selected_count);
            this_scenario_selected_count += selected_count;

          });

          if (element.is_mandatory == 'true') {
            //validate by checking min and max
            if (element.btn_type == "optional") {
              //check min and max 
              if (!(element.min_toping_count <= this_scenario_selected_count && element.max_toping_count >= this_scenario_selected_count)) {
                this.appObject.errors['error_in_scenario_' + element.id] = true;
                error_on_dish = true;
              }
            } else {
              //only min
              if (!(element.min_toping_count <= this_scenario_selected_count)) {
                this.appObject.errors['error_in_scenario_' + element.id] = true;
                error_on_dish = true;
              }
            }
          }
          // // console.log('selected count', this_scenario_selected_count);

        });


      } catch (error) {

      }

    }


    if (this.appObject.errors['mandatory_toping_should_be_selected'] || error_on_dish) {
      this.appObject.errors['mandatory_toping_should_be_selected'] = true;
      return false;
    }


    if (this.editing_cart_item) {
      this.appObject.orderObject.cart.splice(this.editing_cart_item, 1);
      this.appObject.orderObjectDup.cart.splice(this.editing_cart_item, 1);
    }

    let dishDetails = [];
    dishDetails['dish_id'] = this.selectedDish.fullDishInfo.id;
    dishDetails['count'] = 1;

    if (this.selectedDish.fullDishInfo.is_size == 'true') {
      dishDetails['name'] = this.selectedDish.fullDishInfo.sizes[this.selectedDish.size_index].name;
      dishDetails['size_info'] = {
        size_id: this.selectedDish.size_id,
        topping_info: this.selectedDish.selectedTopings
      };
    } else {

      dishDetails['name'] = this.selectedDish.fullDishInfo.name;
      dishDetails['size_info'] = {
        size_id: this.selectedDish.size_id,
        topping_info: this.selectedDish.selectedTopings
      };
    }
    // // console.log('count of dish:', dishDetails);

    this.appObject.orderObject.cart.push({
      'dish_id': dishDetails['dish_id'],
      'count': dishDetails['count'],
      'name': dishDetails['name'],
      'size_info': dishDetails['size_info']

    });
    this.appObject.orderObjectDup.cart.push(this.selectedDish.fullDishInfo);

    await this.calculateCartAmounts();

    this.modalController.dismiss({
      'dismissed': true
    });

    if (this.cartIsOpen) {
      this.editing_cart_item = false;
      const modal = await this.modalController.create({
        component: CartPage,
        cssClass: 'custom-popup'
      });
      await modal.present();
    } else {
      this.animationDrop = true;
      this.className = 'animating'
      setTimeout(function () {
        this.className = '';
        this.animationDrop = false;
      }.bind(this), 1000);
      // if not from cross selling page, then allow to display cart icon
      if (!fromCrossSelling) this.hideCartButton = false;
    }

    // check if the process came from cross selling form, if it is then mark the question as answered
    // otherwise open cross selling popup if available on the selected dish
    let validQuestion = this.selectedDish.fullDishInfo && this.selectedDish.fullDishInfo.cross_selling_products && this.selectedDish.fullDishInfo.cross_selling_products.length ?
      this.selectedDish.fullDishInfo.cross_selling_products.find((csproduct) => csproduct.category.count > 0) : null;

    if (!fromCrossSelling && validQuestion && this.selectedDish.fullDishInfo && this.selectedDish.fullDishInfo.cross_selling_products && this.selectedDish.fullDishInfo.cross_selling_products.length) {
      if (!this.isWebVersion) {
        this.hideCartButton = true;
        const modal = await this.modalController.create({
          component: CrossSellingPage,
          cssClass: 'custom-popup',
          componentProps: {
            'crossSellingProducts': this.selectedDish.fullDishInfo.cross_selling_products,
            'selectedDishCategory': this.selectedDish.fullDishInfo.menu_categories_id.id,
            'title': this.selectedDish.fullDishInfo.name
          }
        });
        await modal.present();
      } else {
        const modal = await this.modalController.create({
          component: ModalPopoverPage,
          componentProps: {
            'type': this.POPOVER_TYPES.CROSS_SELLING.TYPE,
            'title': this.selectedDish.fullDishInfo.name,
            'crossSellingProducts': this.selectedDish.fullDishInfo.cross_selling_products,
            'selectedDishCategory': this.selectedDish.fullDishInfo.menu_categories_id.id,
          }
        });
        await modal.present();
      }
    } else if (fromCrossSelling) {
      this.watchAddToCartFromCrossSelling({ status: 'done' });
    }
  }

  async addToCart(dish, fromCrossSelling: boolean = false) {
    // // console.log('dish : ', dish);
    return new Promise(async (resolve, reject) => {
      if (this.closenotice && !this.isPreOrderAccept) {
        // console.log('Am Called.... : ', this.appObject.selectedRestaurantDetails.openDetails.is_pre_order);
        this.showAlert('error', 'key.restaurant_closed',
          this.appObject.selectedRestaurantDetails.openDetails.text ? this.appObject.selectedRestaurantDetails.openDetails.text : 'closeNote',
          false);
        return;
      }

      this.animationDrop = true;
      this.className = 'animating'
      setTimeout(function () {
        this.className = '';
        this.animationDrop = false;
      }.bind(this), 1000);


      let counted = false;
      let dishDetails = [];

      //for each to check this already exit
      if (this.appObject?.orderObject?.cart.length > 0) {
        for (let index = 0; index < this.appObject.orderObject.cart.length; index++) {

          if (this.appObject.orderObject.cart[index].dish_id == dish.id && dish.is_size == 'false' && dish.is_customise == 'false') {
            counted = true;
            //change count
            this.appObject.orderObject.cart[index].count += 1;

          }

        }

      }

      if (!counted) {

        dishDetails['dish_id'] = dish.id;
        dishDetails['count'] = 1;
        dishDetails['name'] = dish.name;
        this.appObject.orderObject.cart.push({
          'dish_id': dishDetails['dish_id'],
          'count': dishDetails['count'],
          'name': dishDetails['name']
        });

        this.appObject.orderObjectDup.cart.push(dish);


      }


      await this.calculateCartAmounts();

      // check if the user came from crossselling form, if not open to select cross selling product
      let validQuestion = dish && dish.cross_selling_products && dish.cross_selling_products.length ?
        dish.cross_selling_products.find((csproduct) => csproduct.category.count > 0) : null;

      if (!fromCrossSelling && validQuestion && dish && dish.cross_selling_products && dish.cross_selling_products.length) {
        if (!this.isWebVersion) {
          this.hideCartButton = true;
          const modal = await this.modalController.create({
            component: CrossSellingPage,
            cssClass: 'custom-popup',
            componentProps: {
              'crossSellingProducts': dish.cross_selling_products,
              'selectedDishCategory': dish.menu_categories_id.id,
              'title': dish.name
            }
          });
          await modal.present();
        } else {
          const modal = await this.modalController.create({
            component: ModalPopoverPage,
            componentProps: {
              'type': this.POPOVER_TYPES.CROSS_SELLING.TYPE,
              'title': dish.name,
              'crossSellingProducts': dish.cross_selling_products,
              'selectedDishCategory': dish.menu_categories_id.id,
            }
          });
          await modal.present();
        }
      } else if (fromCrossSelling) {
        return resolve({ status: 'done' })
      }
    })

  }

  async setSelectedSize(index) {
    if (index?.detail?.value) {
      index = index.detail.value;
    }
    this.selectedDish.selectedTopings = [];
    this.selectedDish.size_index = index;
    this.selectedDish.size_id = this.selectedDish.fullDishInfo.sizes[index].id;

    this.calcSelectedDishTotal();
  }

  async topingInc(evt, toping, sc, t) {
    let s = this.selectedDish.size_index;
    for (let index = 0; index < this.selectedDish.selectedTopings.length; index++) {
      if (this.selectedDish.selectedTopings[index].topping_id == toping.id) {
        this.selectedDish.selectedTopings[index].count += 1;
        this.selectedDish.selectedTopings[index].total = this.selectedDish.selectedTopings[index].price * this.selectedDish.selectedTopings[index].count;
        toping.count_selected++;
      }
    }

    this.calcSelectedDishTotal();
  }

  async topingDec(evt, toping, sc, t) {
    let s = this.selectedDish.size_index;
    for (let index = 0; index < this.selectedDish.selectedTopings.length; index++) {
      if (this.selectedDish.selectedTopings[index].topping_id == toping.id) {
        if (this.selectedDish.selectedTopings[index].count > 1) {
          this.selectedDish.selectedTopings[index].count -= 1;
          this.selectedDish.selectedTopings[index].total = this.selectedDish.selectedTopings[index].price * this.selectedDish.selectedTopings[index].count;
          toping.count_selected--;
        }

      }
    }

    this.calcSelectedDishTotal();

  }

  async selectTopingOp(evt, sc) {
    // console.log('hereeee', evt);
    let s = this.selectedDish.size_index;
    let toping = [];
    let found = false;
    //check this optional group exist in the selected array, if remove it or add
    let tt_each = [];
    if (this.selectedDish.fullDishInfo.is_size == 'true') {
      tt_each = this.selectedDish.fullDishInfo.sizes[s].scenarios[sc].topings;
    } else {
      tt_each = this.selectedDish.fullDishInfo.scenarios[sc].topings;
    }

    let type = 'checkbox';
    if (typeof evt.detail.checked === 'undefined') {
      type = 'dropdown';

    }

    for (let findex = 0; findex < tt_each.length; findex++) {

      if (type == 'dropdown') {
        let top_id = tt_each[findex].id;
        for (let index = 0; index < this.selectedDish.selectedTopings.length; index++) {
          // console.log('found val', this.selectedDish.selectedTopings[index].topping_id);
          if (top_id == this.selectedDish.selectedTopings[index].topping_id) {
            //exist and remove 
            // console.log('found and removed');
            this.selectedDish.selectedTopings.splice(index, 1);
            found = true;

          }

        }

      }

      if (tt_each[findex].id == evt.detail.value) {
        if (this.selectedDish.fullDishInfo.is_size == 'true') {
          toping = this.selectedDish.fullDishInfo.sizes[s].scenarios[sc].topings[findex];

        } else {
          toping = this.selectedDish.fullDishInfo.scenarios[sc].topings[findex];

        }
      }


    }

    if (!evt.detail.checked) {
      //remove toping
      for (let index = 0; index < this.selectedDish.selectedTopings.length; index++) {
        if (evt.detail.value == this.selectedDish.selectedTopings[index].topping_id) {
          //exist and remove 
          this.selectedDish.selectedTopings.splice(index, 1);
          found = true;

        }

      }
    }

    if (evt.detail.checked || type == 'dropdown') {
      let top = [];
      top['topping_id'] = toping['id'];
      top['count'] = 1;
      top['price'] = toping[this.appObject.selectedDeliveryMethod];

      //for future use
      top['delivery'] = toping['delivery'];
      top['pickup'] = toping['pickup'];
      top['dine_in'] = toping['dine_in'];
      top['name'] = toping['name'];

      //end for future use

      top['total'] = top['count'] * top['price'];

      this.selectedDish.selectedTopings.push({
        'topping_id': top['topping_id'],
        'count': top['count'],
        'price': top['price'],
        'delivery': top['delivery'],
        'pickup': top['pickup'],
        'dine_in': top['dine_in'],
        'total': top['total'],
        'name': top['name']
      });
    }
    // console.log('selected', this.selectedDish.selectedTopings);
    this.calcSelectedDishTotal();

  }

  async selectTopingAddon(evt, toping, sc, t) {
    // // console.log('aaa:', toping);
    let s = this.selectedDish.size_index;

    if (
      (this.selectedDish.fullDishInfo.is_size == 'true' && (typeof this.selectedDish.fullDishInfo.sizes[s].scenarios[sc].topings[t].checked === 'undefined' ||
        !this.selectedDish.fullDishInfo.sizes[s].scenarios[sc].topings[t].checked))
      ||
      (this.selectedDish.fullDishInfo.is_customise == 'true' && (typeof this.selectedDish.fullDishInfo.scenarios[sc].topings[t].checked === 'undefined' ||
        !this.selectedDish.fullDishInfo.scenarios[sc].topings[t].checked))

    ) {

      //add toping
      let found = false;
      for (let index = 0; index < this.selectedDish.selectedTopings.length; index++) {
        if (toping.id == this.selectedDish.selectedTopings[index].topping_id) {

          found = true;

        }
      }

      if (!found) {
        let top = [];
        top['topping_id'] = toping.id;
        top['count'] = 1;
        top['price'] = toping[this.appObject.selectedDeliveryMethod];

        //for future use
        top['delivery'] = toping['delivery'];
        top['pickup'] = toping['pickup'];
        top['dine_in'] = toping['dine_in'];
        top['name'] = toping['name'];

        //end for future use

        top['total'] = top['count'] * top['price'];
        if (this.selectedDish.fullDishInfo.is_size == 'true') {
          this.selectedDish.fullDishInfo.sizes[s].scenarios[sc].topings[t].checked = true;
        } else {
          this.selectedDish.fullDishInfo.scenarios[sc].topings[t].checked = true;
        }
        toping.count_selected = 1;
        this.selectedDish.selectedTopings.push({
          'topping_id': top['topping_id'],
          'count': top['count'],
          'price': top['price'],
          'delivery': top['delivery'],
          'pickup': top['pickup'],
          'dine_in': top['dine_in'],
          'total': top['total'],
          'name': top['name']
        });
      }

    } else {

      //remove toping
      for (let index = 0; index < this.selectedDish.selectedTopings.length; index++) {
        if (toping.id == this.selectedDish.selectedTopings[index].topping_id) {
          //delete

          this.selectedDish.selectedTopings.splice(index, 1);
          if (this.selectedDish.fullDishInfo.is_size == 'true') {
            this.selectedDish.fullDishInfo.sizes[s].scenarios[sc].topings[t].checked = false;

          } else {
            this.selectedDish.fullDishInfo.scenarios[sc].topings[t].checked = false;

          }


        }
      }
    }

    this.calcSelectedDishTotal();

  }



  async topingPopupOpen(dish, isFromCrossSelling: boolean = false) {

    if (dish.is_size == 'true') {
      this.selectedDish.size_id = dish.sizes[0].id;
    } else {
      this.selectedDish.size_id = null;
    }

    this.selectedDish.selectedTopings = [];
    this.selectedDish.fullDishInfo = JSON.parse(JSON.stringify(dish));

    this.calcSelectedDishTotal();

    if (!this.isWebVersion) {
      const modal = await this.modalController.create({
        component: ToppingsPage,
        cssClass: 'custom-popup',
        componentProps: {
          'isFromCrossSelling': isFromCrossSelling
        }
      });

      await modal.present();
    } else {
      const modal = await this.modalController.create({
        component: ModalPopoverPage,
        componentProps: {
          'type': this.POPOVER_TYPES.TOPPINGS.TYPE,
          'title': dish.name,
          'isFromCrossSelling': isFromCrossSelling
        }
      });
      await modal.present();
    }

  }

  async showAlert(type, title = (type === 'success' ? 'Success' : 'Error'), message, autoClose = true) {
    const modal = await this.modalController.create({
      component: AlertPage,
      cssClass: 'custom-popup',
      componentProps: {
        'type': type,
        'message': message,
        'autoClose': autoClose,
        'title': title
      }
    });

    await modal.present();
  }

  async setDeliveryMethod(method, orderProcess = false) {
    this.appObject.selectedDeliveryMethod = method;

    if (method == 'delivery') {

      if (orderProcess) {
        this.router.navigate(['find-near-by-me'], {
          queryParams: {
            isOrderProcess: orderProcess
          }
        });
      } else {
        this.router.navigate(['find-near-by-me']);
      }


    } else {


      if (orderProcess) {
        await this.calculateCartAmounts();
        await this.updateAppObject();
        this.routeToSelectedRestaurant();
      } else {
        this.router.navigate(['restaurant-list']);
      }
    }

  }

  async loginOrCheckout() {
    await this.calculateCartAmounts();
    await this.updateAppObject();
    if (this.appObject.isAuthed) {
      this.router.navigateByUrl('checkout');
    } else {
      this.router.navigate(['login'], {
        queryParams: {
          isOrderProcess: true
        }
      });
    }
  }

  async calculateCartAmounts() {

    let _dtotal_tax_exclusive = 0;
    let _dtotal_tax_inclusive = 0;
    let _dnet_total_without_tax = 0;
    let _dnet_total = 0;
    let _dtotal_discount = 0;

    let _total_dish_count = 0;
    let _dgross_total_with_discount = 0;

    //////////////////////////////GO EACH DISHES
    if (this.appObject?.orderObject?.cart.length > 0) {
      for (let index = 0; index < this.appObject.orderObject.cart.length; index++) {

        //////////////////////////////CLEAR PREVIOUS TAX AND DISCOUNTS
        this.appObject.orderObject.cart[index].tax = [];
        this.appObject.orderObject.cart[index].discount = [];
        //////////////////////////////END CLEAR

        //////////////////////////////COUNT += DISH COUNT
        _total_dish_count += this.appObject.orderObject.cart[index]['count'];
        //////////////////////////////END COUNT

        //////////////////////////////CHECK DISH IS NORMAL / COMBO
        if (this.appObject.orderObjectDup.cart[index].is_customise == 'false' && this.appObject.orderObjectDup.cart[index].is_size == 'false') {
          this.appObject.orderObject.cart[index].dish_price = this.appObject.orderObjectDup.cart[index][this.appObject.selectedDeliveryMethod];
          this.appObject.orderObject.cart[index].total = this.appObject.orderObject.cart[index].dish_price;
          this.appObject.orderObject.cart[index].net_total = this.appObject.orderObject.cart[index].dish_price * this.appObject.orderObject.cart[index]['count'];

        }
        //////////////////////////////END CHECKING


        //////////////////////////////CHECK IS CUSTOMIZE
        if (this.appObject.orderObjectDup.cart[index].is_customise == 'true') {
          this.appObject.orderObject.cart[index].dish_price = this.appObject.orderObjectDup.cart[index][this.appObject.selectedDeliveryMethod];

          //TOPING FOREACH
          let sctoping_total = 0;
          for (let stindex = 0; stindex < this.appObject.orderObject.cart[index].size_info.topping_info.length; stindex++) {

            this.appObject.orderObject.cart[index].size_info.topping_info[stindex]['price'] = this.appObject.orderObject.cart[index].size_info.topping_info[stindex][this.appObject.selectedDeliveryMethod];
            this.appObject.orderObject.cart[index].size_info.topping_info[stindex]['total'] = this.appObject.orderObject.cart[index].size_info.topping_info[stindex]['price'] * this.appObject.orderObject.cart[index].size_info.topping_info[stindex]['count'];

            sctoping_total += this.appObject.orderObject.cart[index].size_info.topping_info[stindex]['total'];

          }

          //END TOPING

          this.appObject.orderObject.cart[index]['topping_price'] = sctoping_total;
          this.appObject.orderObject.cart[index]['total'] = this.appObject.orderObject.cart[index]['dish_price'] + this.appObject.orderObject.cart[index]['topping_price'];
          this.appObject.orderObject.cart[index].net_total = this.appObject.orderObject.cart[index].total * this.appObject.orderObject.cart[index]['count'];

        }
        //////////////////////////////END CHECK CUSTOMIZE

        //////////////////////////////CHECK IS SIZE AVAILABLE
        if (this.appObject.orderObjectDup.cart[index].is_size == 'true') {

          //for each to find correct size values
          for (let sindex = 0; sindex < this.appObject.orderObjectDup.cart[index].sizes.length; sindex++) {
            if (this.appObject.orderObject.cart[index].size_info.size_id == this.appObject.orderObjectDup.cart[index].sizes[sindex].id) {
              //this is correct size
              //re get size value
              this.appObject.orderObject.cart[index]['dish_price'] = this.appObject.orderObjectDup.cart[index].sizes[sindex][this.appObject.selectedDeliveryMethod];

              //TOPING FOREACH
              let toping_total = 0;
              for (let stindex = 0; stindex < this.appObject.orderObject.cart[index].size_info.topping_info.length; stindex++) {

                this.appObject.orderObject.cart[index].size_info.topping_info[stindex]['price'] = this.appObject.orderObject.cart[index].size_info.topping_info[stindex][this.appObject.selectedDeliveryMethod];
                this.appObject.orderObject.cart[index].size_info.topping_info[stindex]['total'] = this.appObject.orderObject.cart[index].size_info.topping_info[stindex]['price'] * this.appObject.orderObject.cart[index].size_info.topping_info[stindex]['count'];

                toping_total += this.appObject.orderObject.cart[index].size_info.topping_info[stindex]['total'];

              }

              //END TOPING
              this.appObject.orderObject.cart[index]['topping_price'] = toping_total;
              this.appObject.orderObject.cart[index]['total'] = this.appObject.orderObject.cart[index]['dish_price'] + this.appObject.orderObject.cart[index]['topping_price'];
              this.appObject.orderObject.cart[index]['net_total'] = this.appObject.orderObject.cart[index]['total'] * this.appObject.orderObject.cart[index]['count'];



            }
          }

        }


        //////////////////////////////END CHECK SIZE AVAILABLE


        //////////////////////////////CLEAR PREVOUS VARIABLES BEFORE CALCULATE
        this.appObject.orderObject.cart[index].total_tax_inclusive = 0;
        this.appObject.orderObject.cart[index].total_tax_exclusive = 0;
        this.appObject.orderObject.cart[index].gross_without_tax_price = 0;
        this.appObject.orderObject.cart[index].gross_total = 0;
        //////////////////////////////END CLEAR


        //////////////////////////////CHECK TAX IS AVAILABLE FOR THIS DISH
        if (this.appObject.orderObjectDup.cart[index]?.tax?.length > 0) {
          for (let oindex = 0; oindex < this.appObject.orderObjectDup.cart[index].tax.length; oindex++) {

            let tax = [];
            tax['tax_id'] = this.appObject.orderObjectDup.cart[index].tax[oindex].id;
            tax['amount'] = this.appObject.orderObjectDup.cart[index].tax[oindex][this.appObject.selectedDeliveryMethod];
            tax['type'] = this.appObject.orderObjectDup.cart[index].tax[oindex].apply_as;
            tax['tax_type'] = this.appObject.orderObjectDup.cart[index].tax[oindex].type;
            tax['name'] = this.appObject.orderObjectDup.cart[index].tax[oindex].title;


            let taxCalculations = await this.getTax(this.appObject.orderObject.cart[index].net_total, tax['tax_type'], tax['type'], tax['amount']);

            if (tax['type'] == "fixed") {
              taxCalculations['tax_amoount'] = taxCalculations['tax_amoount'] * this.appObject.orderObject.cart[index]['count'];
            }

            if (tax['tax_type'] == 'excluded') {
              this.appObject.orderObject.cart[index].total_tax_exclusive += taxCalculations['tax_amoount'];
            } else {
              this.appObject.orderObject.cart[index].total_tax_inclusive += taxCalculations['tax_amoount'];
            }

            tax['tax_amount'] = taxCalculations['tax_amoount'];

            this.appObject.orderObject.cart[index].tax.push({
              'tax_id': tax['tax_id'],
              'amount': tax['amount'],
              'type': tax['type'],
              'tax_type': tax['tax_type'],
              'name': tax['tax_id'],
              'tax_amount': tax['tax_amount']
            });

          }

        }

        this.appObject.orderObject.cart[index].gross_without_tax_price = this.appObject.orderObject.cart[index].net_total - this.appObject.orderObject.cart[index].total_tax_inclusive;
        this.appObject.orderObject.cart[index].gross_total = this.appObject.orderObject.cart[index].gross_without_tax_price + this.appObject.orderObject.cart[index].total_tax_inclusive + this.appObject.orderObject.cart[index].total_tax_exclusive;
        //////////////////////////////END CHECK TAX FOR DISH


        //////////////////////////////DISH TAXES +=
        _dtotal_tax_exclusive += this.appObject.orderObject.cart[index].total_tax_exclusive;
        _dtotal_tax_inclusive += this.appObject.orderObject.cart[index].total_tax_inclusive;
        _dnet_total_without_tax += this.appObject.orderObject.cart[index].gross_without_tax_price;
        _dnet_total += this.appObject.orderObject.cart[index].gross_total;
        //////////////////////////////END DISH TAXES +=


        //////////////////////////////DISCOUNT VARIABLES
        this.appObject.orderObject.cart[index].total_discount = 0;
        this.appObject.orderObject.cart[index].gross_total_with_discount = 0;
        this.appObject.orderObject.cart[index].comment = "";


        //////////////////////////////CHECK DISCOUNT AVAILABILITY
        if (this.appObject.orderObjectDup.cart[index]?.discounts?.length > 0) {
          for (let dindex = 0; dindex < this.appObject.orderObjectDup.cart[index].discounts.length; dindex++) {

            if (this.appObject.orderObjectDup.cart[index].discounts[dindex].apply_platform == 'both' || this.appObject.orderObjectDup.cart[index].discounts[dindex].apply_platform == config.plf) {
              let disc = [];
              disc['discount_id'] = this.appObject.orderObjectDup.cart[index].discounts[dindex].id;
              disc['amount'] = this.appObject.orderObjectDup.cart[index].discounts[dindex].amount;
              disc['type'] = this.appObject.orderObjectDup.cart[index].discounts[dindex].apply_as;
              disc['name'] = this.appObject.orderObjectDup.cart[index].discounts[dindex].title;


              let discountCalculations = await this.getDiscount(this.appObject.orderObject.cart[index].gross_total, disc['type'], disc['amount']);

              if (disc['type'] == "fixed") {
                discountCalculations['dis_amoount'] = discountCalculations['dis_amoount'] * this.appObject.orderObject.cart[index]['count'];
              }

              disc['discount_amount'] = discountCalculations['dis_amoount'];

              this.appObject.orderObject.cart[index].total_discount += discountCalculations['dis_amoount'];
              this.appObject.orderObject.cart[index].discount.push({
                'discount_id': disc['discount_id'],
                'amount': disc['amount'],
                'type': disc['type'],
                'name': disc['name'],
                'discount_amount': disc['discount_amount']

              });
            }

          }
        }

        this.appObject.orderObject.cart[index].gross_total_with_discount = this.appObject.orderObject.cart[index].gross_total - this.appObject.orderObject.cart[index].total_discount;
        _dgross_total_with_discount += this.appObject.orderObject.cart[index].gross_total_with_discount;
        //////////////////////////////END CHECK DISCOUNT AVAILABILITY
        _dtotal_discount += this.appObject.orderObject.cart[index].total_discount;

      }
    }
    //////////////////////////////END GO EACH DISHES


    //////////////////////////////ORDER TAX VARIABLES
    let _ot_total_tax_exclusive = 0;
    let _ot_total_tax_inclusive = 0;
    let _ot_net_total_without_tax = 0;
    let _ot_net_total = 0;


    //////////////////////////////ORDER TAX CHECK
    this.appObject.orderObject.order_tax = [];

    if (this.appObject.selectedRestaurantDetails?.resturent_order_tax_ids?.length > 0) {
      for (let otindex = 0; otindex < this.appObject.selectedRestaurantDetails.resturent_order_tax_ids.length; otindex++) {
        let otax = [];
        otax['tax_id'] = this.appObject.selectedRestaurantDetails.resturent_order_tax_ids[otindex].id;
        otax['amount'] = this.appObject.selectedRestaurantDetails.resturent_order_tax_ids[otindex][this.appObject.selectedDeliveryMethod];
        otax['type'] = this.appObject.selectedRestaurantDetails.resturent_order_tax_ids[otindex].apply_as;
        otax['tax_type'] = this.appObject.selectedRestaurantDetails.resturent_order_tax_ids[otindex].type;
        otax['name'] = this.appObject.selectedRestaurantDetails.resturent_order_tax_ids[otindex].title;


        let ftaxCalculations = await this.getTax(_dgross_total_with_discount, otax['tax_type'], otax['type'], otax['amount']);
        otax['tax_amount'] = ftaxCalculations['tax_amoount'];

        if (otax['tax_type'] == 'excluded') {
          _ot_total_tax_exclusive += otax['tax_amount'];
        } else {
          _ot_total_tax_inclusive += otax['tax_amount'];
        }

        this.appObject.orderObject.order_tax.push({
          'tax_id': otax['tax_id'],
          'amount': otax['amount'],
          'type': otax['type'],
          'tax_type': otax['tax_type'],
          'name': otax['name'],
          'tax_amount': otax['tax_amount']
        });
      }
    }

    _ot_net_total_without_tax = _dgross_total_with_discount - _ot_total_tax_inclusive;
    _ot_net_total = _ot_net_total_without_tax + _ot_total_tax_inclusive + _ot_total_tax_exclusive;
    //////////////////////////////END ORDER TAX CHECK



    //////////////////////////////DELIVERY  COST
    let _ototal_discount = 0;

    //////////////////////////////ORDER DEFAULT DISCOUNT CHECK
    this.appObject.orderObject.discounts = [];
    let delm = 'deliver';

    switch (this.appObject.selectedDeliveryMethod) {
      case 'pickup':
        delm = 'pickup';
        break;
      case 'dine_in':
        delm = 'dinein';
        break;
    }
    if (this.appObject.selectedRestaurantDetails[delm + '_discount']) {
      let oddis = [];
      oddis['amount'] = this.appObject.selectedRestaurantDetails[delm + '_discount'];
      oddis['type'] = 'percentage';
      oddis['name'] = 'anytime ' + oddis['amount'] + '%';


      let discountCalculations = await this.getDiscount(_ot_net_total, oddis['type'], oddis['amount']);
      oddis['discount_amount'] = discountCalculations['dis_amoount'];

      _ototal_discount += oddis['discount_amount'];

      this.appObject.orderObject.discounts.push({
        'amount': oddis['amount'],
        'type': oddis['type'],
        'name': oddis['name'],
        'discount_amount': oddis['discount_amount']
      });

    }

    //////////////////////////////END ORDER DEFAULT DISCOUNT CHECK

    //////////////////////////////TIME LIMIT DISCOUNT
    if (this.appObject.selectedRestaurantDetails.discountRestaurant.isActive && this.appObject.selectedRestaurantDetails.discountRestaurant.isActive == "true") {
      let apply = false;
      let amount = 0;
      switch (this.appObject.selectedDeliveryMethod) {
        case 'delivery':
          if (this.appObject.selectedRestaurantDetails.discountRestaurant.isDeliveryAvailable == 'true') {
            apply = true;
            amount = this.appObject.selectedRestaurantDetails.discountRestaurant.deliveryCost;
          }
          break;

        case 'pickup':
          if (this.appObject.selectedRestaurantDetails.discountRestaurant.isPickUpAvailable == 'true') {
            apply = true;
            amount = this.appObject.selectedRestaurantDetails.discountRestaurant.pickUpCost;

          }
          break;
        case 'dine_in':
          if (this.appObject.selectedRestaurantDetails.discountRestaurant.isDineInAvailable == 'true') {
            apply = true;
            amount = this.appObject.selectedRestaurantDetails.discountRestaurant.dineInCost;

          }
          break;
      }

      if (apply) {
        //check is reached minimum details
        if (this.appObject.selectedRestaurantDetails.discountRestaurant.minimumDishes <= _total_dish_count && _dnet_total >= this.appObject.selectedRestaurantDetails.discountRestaurant.minimumAmount) {
          let olddis = [];
          olddis['amount'] = amount;
          olddis['type'] = 'percentage';
          olddis['name'] = this.appObject.selectedRestaurantDetails.discountRestaurant.displayText;


          let ldiscountCalculations = await this.getDiscount(_ot_net_total, olddis['type'], olddis['amount']);
          olddis['discount_amount'] = ldiscountCalculations['dis_amoount'];

          _ototal_discount += olddis['discount_amount'];

          this.appObject.orderObject.discounts.push({
            'amount': olddis['amount'],
            'type': olddis['type'],
            'name': olddis['name'],
            'discount_amount': olddis['discount_amount']
          });

        }
      }

    }

    //////////////////////////////END TIME LIMIT DISCOUNT


    //////////////////////////////CHECK TIME LIMIT DISCOUNT

    if (this.appObject.selectedRestaurantDetails.discountList.length > 0) {
      for (let tlindex = 0; tlindex < this.appObject.selectedRestaurantDetails.discountList.length; tlindex++) {
        let tld = [];
        tld['discount_id'] = this.appObject.selectedRestaurantDetails.discountList[tlindex].id;
        tld['amount'] = this.appObject.selectedRestaurantDetails.discountList[tlindex].amount;
        tld['type'] = this.appObject.selectedRestaurantDetails.discountList[tlindex].apply_as;
        tld['name'] = this.appObject.selectedRestaurantDetails.discountList[tlindex].title;


        let tldiscountCalculations = await this.getDiscount(_ot_net_total, tld['type'], tld['amount']);
        tld['discount_amount'] = tldiscountCalculations['dis_amoount'];

        _ototal_discount += tld['discount_amount'];

        this.appObject.orderObject.discounts.push({
          'discount_id': tld['discount_id'],
          'amount': tld['amount'],
          'type': tld['type'],
          'name': tld['name'],
          'discount_amount': tld['discount_amount']
        });

      }
    }

    //////////////////////////////END CHECK TIME LIMIT DISCOUNT

    //selected coupon
    if (typeof this.appObject.selectedCoupon.length !== 'undefined' && this.appObject.selectedCoupon.length > 0) {
      let oscoup = [];
      oscoup['discount_id'] = this.appObject.selectedCoupon[0].id;
      oscoup['amount'] = this.appObject.selectedCoupon[0].amount;
      oscoup['type'] = this.appObject.selectedCoupon[0].apply_as;
      oscoup['coupon_code'] = this.appObject.selectedCoupon[0].coupon_code;
      oscoup['name'] = this.appObject.selectedCoupon[0].title;


      let couponCalculations = await this.getDiscount(_ot_net_total, oscoup['type'], oscoup['amount']);
      oscoup['discount_amount'] = couponCalculations['dis_amoount'];

      _ototal_discount += oscoup['discount_amount'];

      this.appObject.orderObject.discounts.push({
        'discount_id': oscoup['discount_id'],
        'amount': oscoup['amount'],
        'type': oscoup['type'],
        'coupon_code': oscoup['coupon_code'],
        'name': oscoup['name'],
        'discount_amount': oscoup['discount_amount']
      });

    }



    this.appObject.orderObject.cart_summery.min_reached = true;


    //delivery charge
    let delivery_cost = 0;
    //by distance
    let distance = Math.round(this.appObject.selectedRestaurantDetails.distance_to_location);
    if (distance == 0) {
      distance = 1;
    }
    let minOrderAmounts = JSON.parse(this.appObject.selectedRestaurantDetails.min_order_amounts);

    this.appObject.orderObject.cart_summery.min_amount = 0;

    if (this.appObject.selectedDeliveryMethod == 'delivery') {
      if (this.appObject.selectedRestaurantDetails.delivery_charge_type == "fixed_amount") {
        if (this.appObject.selectedRestaurantDetails.fixed_delivery_amount != null && this.appObject.selectedRestaurantDetails.fixed_delivery_amount_apply_as != null) {
          let dcharge = await this.getDeliveryCost(_ot_net_total, this.appObject.selectedRestaurantDetails.fixed_delivery_amount_apply_as, this.appObject.selectedRestaurantDetails.fixed_delivery_amount);
          delivery_cost = await this.getNumberValue(dcharge['del_amoount']);
        }
      } else {

        let byDistance = JSON.parse(this.appObject.selectedRestaurantDetails.delivery_cost);

        if (byDistance.length >= distance) {
          if (byDistance[distance - 1].cost) {
            delivery_cost = await this.getNumberValue(byDistance[distance - 1].cost);
          }
        }

      }

      this.appObject.orderObject.cart_summery.min_amount = await this.getNumberValue(minOrderAmounts[distance - 1].amount);
      if (Number.isNaN(this.appObject.orderObject.cart_summery.min_amount)) {
        this.appObject.orderObject.cart_summery.min_amount = 0;
      }

      if (this.appObject.orderObject.cart_summery.min_amount <= _ot_net_total) {
        this.appObject.orderObject.cart_summery.min_reached = true;
      } else {
        this.appObject.orderObject.cart_summery.min_reached = false;
      }

      //check min order amount is reached
      if (this.appObject.selectedRestaurantDetails.is_free_delivery == 'true') {
        if (this.appObject.orderObject.cart_summery.min_reached) {
          delivery_cost = 0;
        }
      }

    }


    //////////////////////////////ORDER DELEVERY TAX VARIABLES
    let _dt_total_tax_exclusive = 0;
    let _dt_total_tax_inclusive = 0;
    let _dt_net_total_without_tax = 0;
    let _dt_net_total = 0;

    //////////////////////////////ORDER DELEVERY TAX CHECK
    this.appObject.orderObject.delivery_tax = [];

    if (this.appObject.selectedDeliveryMethod == 'delivery' && delivery_cost > 0) {

      if (this.appObject.selectedRestaurantDetails?.resturent_delivery_tax_ids?.length > 0) {
        for (let otindex = 0; otindex < this.appObject.selectedRestaurantDetails.resturent_delivery_tax_ids.length; otindex++) {
          let dtax = [];
          dtax['tax_id'] = this.appObject.selectedRestaurantDetails.resturent_delivery_tax_ids[otindex].id;
          dtax['amount'] = this.appObject.selectedRestaurantDetails.resturent_delivery_tax_ids[otindex][this.appObject.selectedDeliveryMethod];
          dtax['type'] = this.appObject.selectedRestaurantDetails.resturent_delivery_tax_ids[otindex].apply_as;
          dtax['tax_type'] = this.appObject.selectedRestaurantDetails.resturent_delivery_tax_ids[otindex].type;
          dtax['name'] = this.appObject.selectedRestaurantDetails.resturent_delivery_tax_ids[otindex].title;


          let ftaxCalculations = await this.getTax(delivery_cost, dtax['tax_type'], dtax['type'], dtax['amount']);
          dtax['tax_amount'] = ftaxCalculations['tax_amoount'];

          if (dtax['tax_type'] == 'excluded') {
            _dt_total_tax_exclusive += dtax['tax_amount'];
          } else {
            _dt_total_tax_inclusive += dtax['tax_amount'];
          }

          this.appObject.orderObject.delivery_tax.push({
            'tax_id': dtax['tax_id'],
            'amount': dtax['amount'],
            'type': dtax['type'],
            'tax_type': dtax['tax_type'],
            'name': dtax['name'],
            'tax_amount': dtax['tax_amount']
          });
        }
      }
    }

    //_dt_net_total_without_tax = _dnet_total_without_tax - _dt_total_tax_inclusive;
    // _dt_net_total += (_dnet_total_without_tax - _dt_total_tax_inclusive) + _dt_total_tax_exclusive;

    //////////////////////////////END ORDER DELEVERY TAX CHECK

    //summery net_total
    this.appObject.orderObject.cart_summery.total_tax_inclusive = _ot_total_tax_inclusive;
    this.appObject.orderObject.cart_summery.total_tax_exclusive = _ot_total_tax_exclusive;
    this.appObject.orderObject.cart_summery.net_total_without_tax = _ot_net_total_without_tax;
    this.appObject.orderObject.cart_summery.net_total = _ot_net_total;
    this.appObject.orderObject.cart_summery.total_discount = _ototal_discount;


    this.appObject.orderObject.cart_summery.total_with_discount_price = this.appObject.orderObject.cart_summery.net_total - this.appObject.orderObject.cart_summery.total_discount;
    this.appObject.orderObject.cart_summery.delivery_cost = delivery_cost + _dt_total_tax_exclusive;
    this.appObject.orderObject.cart_summery.gross_total = this.appObject.orderObject.cart_summery.total_with_discount_price + this.appObject.orderObject.cart_summery.delivery_cost;
    this.appObject.orderObject.cart_summery.total_dish_count = _total_dish_count;


    this.updateAppObject();

  }

  async getTax(amount, type, apply_as, tax_value) {
    let tax = [];

    if (apply_as == 'percentage') {
      if (type == 'excluded') {
        tax['tax_amoount'] = (amount / 100) * tax_value;
        tax['amount_before_tax'] = amount;
        tax['amount_after_tax'] = amount + (amount / 100) * tax_value;

      } else {
        tax['tax_amoount'] = amount - (amount * 100) / (100 + tax_value);
        tax['amount_before_tax'] = amount - tax['tax_amoount'];
        tax['amount_after_tax'] = amount;
      }
    } else {
      if (type == 'excluded') {
        tax['tax_amoount'] = tax_value;
        tax['amount_before_tax'] = amount;
        tax['amount_after_tax'] = amount + tax_value;
      } else {
        tax['tax_amoount'] = tax_value;
        tax['amount_before_tax'] = amount - tax_value;
        tax['amount_after_tax'] = amount;
      }
    }

    return tax;
  }

  async getDiscount(amount, apply_as, dis_value) {
    let dis = [];

    if (apply_as == 'percentage') {
      dis['dis_amoount'] = (amount / 100) * dis_value;
      dis['amount_before_dis'] = amount;
      dis['amount_after_dis'] = amount + (amount / 100) * dis_value;

    } else {
      dis['dis_amoount'] = dis_value;
      dis['amount_before_dis'] = amount;
      dis['amount_after_dis'] = amount + dis_value;
    }

    return dis;
  }

  async getDeliveryCost(amount, apply_as, del_value) {
    let del = [];

    if (apply_as == 'percentage') {
      del['del_amoount'] = (amount / 100) * del_value;
      del['amount_before_del'] = amount;
      del['amount_after_del'] = amount + (amount / 100) * del_value;

    } else {
      del['del_amoount'] = del_value;
      del['amount_before_del'] = amount;
      del['amount_after_del'] = amount + del_value;
    }

    return del;
  }

  async changeAddress() {
    this.routeToRestaurantList = true;
    this.router.navigate(['/find-near-by-me'], { replaceUrl: true });
  }

  async getLanguages() {
    await this.requestService.post('language/list', {
    }).then(data => {
      if (data.status) {
        this.appObject.langList = data.lang;
      }
    }, error => {
      this.appObject.isLoading = false;
    });
  }

  async addCoupon(code) {
    return await this.requestService.post('coupon/status', {
      'code': code,
      'restaurant_id': this.appObject.selectedRestaurantId,
      'customer_id': this.appObject.userId,
      'device_type': config.plf,
      'device_id': this.appObject.deviceUUID,
      'discounts': this.appObject.orderObject.discounts
    });
  }

  async changeLanguage(val) {
    this.currentLang = val.detail.value;
    this.translate.setDefaultLang(this.currentLang);
    this.translate.use(this.currentLang);
    await this.storage.set('lang', this.currentLang);
    await this.getSettings();
    this.updateAppObject();
  }



  async getSettings() {

    await this.requestService.post('settings', {
      'device_id': this.appObject.deviceUUID,
      'language': this.appObject.currentLang,
      'device_type': config.plf
    }).then(data => {
      if (data.status) {

        let pages_in_side_menu = [];

        data.data.pages.forEach(element => {
          if (element.is_show_on_side_menu == "true") {
            pages_in_side_menu.push(element);
          }
        });

        this.appObject.settings = data.data;
        this.appObject.sideMenuPages = pages_in_side_menu;


        // get pages details , extract data that really need and set to the localstorage
        let pageDetails = data.data && data.data.pages && data.data.pages.length ? data.data.pages.reduce(
          (a, o) => (a.push({ id: o.id, slug: o.slug, name: o.name, content: o.content }), a),
          []
        ) : [];
        this.storage.set(this.STORAGE_KEYS.PAGES_KEY, JSON.stringify(pageDetails));

        // if web version, then get default restaurant details
        if (this.isWebVersion && this.appObject.settings && this.appObject.settings.main_restaurant && this.appObject.settings.main_restaurant.slug) {
          this.getRestaurantBySlug(this.appObject.settings.main_restaurant.slug);
        }

      }
    }, error => {
      this.appObject.isLoading = false;
    });
  }

  async openLink(url) {
    const browser = this.iab.create(url);
  }

  async openSideMenu() {
    this.menu.enable(true, 'sidemenu');
    this.menu.open('sidemenu');
  }

  closeMenu() {
    this.menu.close('sidemenu');
  }

  pageSetup(page) {
    switch (page) {
      case 'find-near-by-me':
        this.isFooterMenu = false;
        this.appObject.errors = [];
        break;

      default:
        break;
    }
  }

  goTo(url) {
    //  this.menu.close('sidemenu');
    //this.navController.navigateRoot(url);
    setTimeout(() => {
      this.router.navigate(url);
    }, 200);
    // console.log('here');
  }

  routeToSelectedRestaurant() {
    // console.log('restaurant/' + this.appObject.selectedRestaurant);
    this.router.navigate(['restaurant/' + this.appObject.selectedRestaurant]);
  }


  formataNumero(e: any, separador: string = '.', decimais: number = 2) {
    let a: any = e.value.split('');
    let ns: string = '';
    a.forEach((c: any) => { if (!isNaN(c)) ns = ns + c; });
    ns = parseInt(ns).toString();
    if (ns.length < (decimais + 1)) { ns = ('0'.repeat(decimais + 1) + ns); ns = ns.slice((decimais + 1) * -1); }
    let ans = ns.split('');
    let r = '';
    for (let i = 0; i < ans.length; i++) if (i == ans.length - decimais) r = r + separador + ans[i]; else r = r + ans[i];
    e.value = r;
  }



  async openCart() {

    // console.log(this.appObject);
    this.animationDrop = true;
    this.className = 'animating'

    setTimeout(() => {
      this.className = '';
      this.animationDrop = false;
      this.openCartPopup();
    }, 1500);


  }

  async openCartPopup() {

    this.hideCartButton = true;

    const cartModal = await this.modalController.create({
      component: CartPage,
      cssClass: 'custom-popup'
    });

    await cartModal.present();
  }

  async placeOrder(isWebVersion: boolean = false) {

    /*
     let order_details = {
       delivery_type: this.appObject.selectedDeliveryMethod,
       delivery_time: '',
       delivery_address: {
         name: this.appObject.myAddress.name,
         lat: this.appObject.myAddress.lat,
         lng: this.appObject.myAddress.lng,

    this.appObject.orderObject.order_details.delivery_address.name = this.appObject.myAddress.name;
    this.appObject.orderObject.order_details.delivery_address.lat = this.appObject.myAddress.lat;
    this.appObject.orderObject.order_details.delivery_address.lng = this.appObject.myAddress.lng;
       },
       payment_type: 'cod',
       name: 'viraj harshana',
       email: 'viraj@local.com',
       mobile_number: '071958475543',
       backyard: '',
       special_note:''
     };
     */

    //store user details in cache
    if (this.appObject.user.type == 'guest') {
      let full_name = this.appObject.orderObject.order_details.name;
      let name = full_name.split(" ");
      this.appObject.user.fname = name[0];
      this.appObject.user.lname = name.length > 2 ? name[1] + ' ' + name[2] : name[1];
      this.appObject.user.email = this.appObject.orderObject.order_details.email;
      this.appObject.user.telephone = this.appObject.orderObject.order_details.mobile_number;
    }



    this.appObject.orderObject.order_details.delivery_address.name = this.appObject.myAddress.name;
    this.appObject.orderObject.order_details.delivery_address.lat = this.appObject.myAddress.lat;
    this.appObject.orderObject.order_details.delivery_address.lng = this.appObject.myAddress.lng;


    this.appObject.orderObject['order_details'] = this.appObject.orderObject.order_details;

    this.appObject.orderObject['device_id'] = this.appObject.deviceUUID;
    this.appObject.orderObject['lang'] = this.appObject.currentLang;
    this.appObject.orderObject['resturent_id'] = this.appObject.selectedRestaurantId;
    this.appObject.orderObject['device_type'] = config.plf;
    this.appObject.orderObject['customer_id'] = this.appObject.userId;

    await this.calculateCartAmounts();

    this.appObject.isLoading = true;

    return new Promise(async (resolve, reject) => {
      await this.requestService.post('order/add', this.appObject.orderObject).then(data => {
        // console.log('inside:', this.appObject.orderObject);
        if (data.status) {
          if (this.appObject.orderObject.order_details.payment_type == 'cod' || this.appObject.orderObject.order_details.payment_type == 'ecCard') {
            //success
            this.clearCart();
            // if not the web version, then redirect to order summary page
            if (!isWebVersion) this.router.navigate(['/order-summery/' + data.payment_id]);
            else {
              this.appObject.isLoading = false;
              return resolve(data);
            }
          } else {

            //open payments
            const url = data.url;

            if (isWebVersion) {

              var windoeRef = window.open(url);

              var Int = setInterval(() => {
                if (windoeRef.closed) {
                  clearInterval(Int);
                  Int = null;
                  //order status check
                  this.requestService.post('order/payment/status', { payment_id: data.payment_id, device_id: this.appObject.deviceUUID, lang: this.appObject.currentLang }).then(paymentStatus => {
                    if (paymentStatus.status && paymentStatus.data.status === "paid") {
                      //payment done
                      this.clearCart();

                      this.appObject.isLoading = false;
                      return resolve(data);
                    } else {
                      //err
                      this.translate.get('key.payment_faild_msg').toPromise().then((response) => {
                        this.showAlert('error', '', response);
                      });
                    }
                  });
                } else {
                  //order status check
                  this.requestService.post('order/payment/status', { payment_id: data.payment_id, device_id: this.appObject.deviceUUID, lang: this.appObject.currentLang }).then(paymentStatus => {
                    this.appObject.isLoading = false;
                    if (paymentStatus.status && paymentStatus.data.status === "paid") {
                      //payment done
                      clearInterval(Int);
                      windoeRef.close();
                      windoeRef = null;
                      this.clearCart();

                      return resolve(data);
                    } else if (paymentStatus.status && paymentStatus.data.status === "cancelled") {
                      //payment cancelled
                      clearInterval(Int);
                      windoeRef.close();
                      windoeRef = null;
                      //err
                      this.translate.get('key.payment_faild_msg').toPromise().then((response) => {
                        this.showAlert('error', '', response);
                      });
                      return reject(data);
                    } else {
                      //err
                      this.translate.get('key.payment_faild_msg').toPromise().then((response) => {
                        this.showAlert('error', '', response);
                      });
                    }
                  });
                }
              }, 2000);

              // this.appObject.isLoading = false;
              // return resolve(data);
            } else {
              //app start

              const options: InAppBrowserOptions = {
                location: 'no',
                clearcache: 'yes',
                zoom: 'yes',
                toolbar: 'yes',
                closebuttoncaption: 'close'
              };
              //open in app
              const browser: any = this.iab.create(url, '_blank', options);
              //event subscribe
              browser.on('loadstop').subscribe(event => {
                //order status check
                this.requestService.post('order/payment/status', { payment_id: data.payment_id, device_id: this.appObject.deviceUUID, lang: this.appObject.currentLang }).then(paymentStatus => {
                  if (paymentStatus.status && paymentStatus.data.status === "paid") {
                    setTimeout(() => {
                      //payment done
                      browser.close();
                      this.clearCart();
                      this.router.navigate(['/order-summery/' + data.payment_id]);
                    }, 3000);
                  } else if (paymentStatus.status && paymentStatus.data.status === "cancelled") {
                    setTimeout(() => {
                      //payment cancelled
                      browser.close();
                      //err
                      this.translate.get('key.payment_faild_msg').toPromise().then((response) => {
                        this.showAlert('error', '', response);
                      });
                    }, 3000);
                  } else {
                    //payment cancelled
                    const navUrl = event.url;
                    if (navUrl.includes('return')) {
                      setTimeout(() => {
                        browser.close();
                        //err
                        this.translate.get('key.payment_faild_msg').toPromise().then((response) => {
                          this.showAlert('error', '', response);
                        });
                      }, 3000);
                    }
                  }
                });
              });
              //end
            }

          }
        } else {
          this.showToast(data.msg);
          if (isWebVersion) {
            this.appObject.isLoading = false;
            return reject(data);
          }
        }
        this.appObject.isLoading = false;

      }, error => {
        this.appObject.isLoading = false;
      });
    });

  }

  async showToast(msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 3000,
      position: 'bottom'
    });
    toast.present();
  }


  async checkout() {
    this.modalController.dismiss();

    if (this.appObject.isAuthed) {
      this.router.navigateByUrl('checkout');
    } else {
      this.router.navigate(['login'], {
        queryParams: {
          isOrderProcess: true
        }
      });
    }
  }

  async checkoutAsGuest() {
    this.appObject.user.type = 'guest';
    this.router.navigateByUrl('checkout');
  }

  async getOpenTimes() {

    return await this.requestService.post('checkout/delivery-time/list', {
      'restaurant_id': this.appObject.selectedRestaurantId,
      'device_id': this.appObject.deviceUUID,
      'device_type': config.plf,
      'delivery_method': this.appObject.selectedDeliveryMethod,
      'lang': this.appObject.currentLang
    });

  }

  async forgotPasswordEmail(value) {
    this.appObject.errors = [];
    this.requestService.post('forgot/password', value).then(data => {
      if (data.status) {
        this.email_content = false;
        this.code_content = true;
      } else {
        this.appObject.errors = data.msg[0];
      }
    }, error => {
      this.appObject.isLoading = false;
    });
  }

  async confirmVerifyCode(value) {
    this.appObject.errors = [];
    this.requestService.post('reset/password/verify', value).then(data => {
      if (data.status) {
        this.repeatpass_content = true;
        this.email_content = false;
        this.code_content = false;
      } else {
        this.appObject.errors = data.msg;
      }
    }, error => {
      this.appObject.isLoading = false;
    });
  }

  async resetOldPassword(value) {
    return new Promise((resolve, reject) => {
      this.appObject.errors = [];
      this.requestService.post('reset/password', value).then(data => {
        if (data.status) {
          this.repeatpass_content = false;
          this.email_content = true;
          this.code_content = false;
          return resolve('true');
        } else {
          this.appObject.errors = data.msg.password[0];
          return resolve('false');
        }
      }, error => {
        this.appObject.isLoading = false;
      });
    })
  }

  /**
   * update Profile
  */
  async updateProfile(profile_details: any) {

    this.appObject.errors = [];
    profile_details['lang'] = this.appObject.currentLang;
    profile_details['device_id'] = this.appObject.deviceUUID;
    profile_details['device_type'] = config.plf;

    return await this.requestService.post('customer/edit', profile_details);

  }

  /**
   * update Profile password
  */
  async updatePassword(passwordDetails: any) {

    this.appObject.errors = [];
    passwordDetails['lang'] = this.appObject.currentLang;
    passwordDetails['device_id'] = this.appObject.deviceUUID;
    passwordDetails['device_type'] = config.plf;

    return await this.requestService.post('customer/password/edit', passwordDetails);

  }

  /**
   * get user Details
  */
  async getUserDetails(profile_details: any) {
    this.appObject.errors = [];
    profile_details['lang'] = this.appObject.currentLang;
    profile_details['device_id'] = this.appObject.deviceUUID;
    profile_details['device_type'] = config.plf;

    return await this.requestService.post('customer/details', profile_details);

  }


  async getNumberValue(data) {
    return parseFloat(data);
  }

  /**
   * get user orders
  */
  async customerOrderList(customerDetails: any) {
    this.appObject.errors = [];
    customerDetails['lang'] = this.appObject.currentLang;
    customerDetails['device_id'] = this.appObject.deviceUUID;
    customerDetails['device_type'] = config.plf;

    return await this.requestService.post('order/list', customerDetails);
  }

  async getOrderInfo(orderUnique: any) {

    return await this.requestService.post('order/details', {
      lang: this.appObject.currentLang,
      device_id: this.appObject.deviceUUID,
      device_type: config.plf,
      payment_id: orderUnique

    });
  }

  /**
   * Save user review from order summary
   * @param reviewData user review details
   * @returns promise
   */
  async insertUserReview(reviewData: any) {
    reviewData['lang'] = this.appObject.currentLang;
    reviewData['device_id'] = this.appObject.deviceUUID;
    reviewData['device_type'] = config.plf;
    return new Promise((resolve, reject) => {
      this.requestService.post('order/review', reviewData).then((response) => {
        return resolve(response);
      }).catch((error) => {
        return reject(error);
      });
    })
  }

  /**
   * Will return given date formatted as yyyy-mm-dd hh:mm am/pm
   * @param dateObject date object to format
   * @returns string formated date
   */
  formatDateObject(dateObject) {
    let month = dateObject.getMonth() + 1;
    month = month <= 9 ? '0' + month : month;
    let day = dateObject.getDate() <= 9 ? '0' + dateObject.getDate() : dateObject.getDate();
    let year = dateObject.getFullYear();
    let hour = dateObject.getHours() <= 9 ? '0' + dateObject.getHours() : dateObject.getHours();
    let minute = dateObject.getMinutes() <= 9 ? '0' + dateObject.getMinutes() : dateObject.getMinutes();
    return year + '-' + month + '-' + day + ' ' + hour + ':' + minute;
  }

  /**
   * Fetch opening hours of give restaurant id
   * @param restaurantID restaurant id to fetch data
   * @returns promise
   */
  getRestaurantOpeningHours(restaurantID) {
    return new Promise((resolve, reject) => {
      let data = {
        lang: this.appObject.currentLang,
        device_id: this.appObject.deviceUUID,
        device_type: config.plf
      }
      this.requestService.post('open-hours/restaurant/' + restaurantID, data).then((response) => {
        return resolve(response);
      }).catch((error) => {
        return reject(error);
      });
    });
  }


  // ------------------------------------- WEB API CALLS ONLY -----------------------------------

  /**
   * Get most popular dishes to display on home page
   * @returns promise
   */
  web_getPopularDishesHome() {
    return new Promise((resolve, reject) => {
      let data = {
        lang: this.appObject.currentLang,
        device_id: this.appObject.deviceUUID,
        device_type: config.plf
      }
      this.requestService.post('home/favourite-list', data).then((response) => {
        return resolve(response);
      }).catch((error) => {
        return reject(error);
      });
    });
  }

  clearSelectedRestaurant() {
    this.appObject.selectedRestaurant = null;
    this.appObject.selectedRestaurantDetails = [];
    this.appObject.selectedRestaurantId = null;
  }

  routeToOrder() {
    this.clearSelectedRestaurant();
    this.router.navigate(['/delivery-method']);
  }

  async getAdditivesAndSub() {
    this.appObject.isLoading = true;

    if (this.appObject.deviceUUID == "") {
      setTimeout(() => {
        this.getAdditivesAndSub();
      }, 100);
    } else {

      this.requestService.post('home/additive-list', {
        'lang': this.appObject.currentLang,
        'device_id': this.appObject.deviceUUID,
        'device_type': config.plf
      }).then(data => {
        if (data.status) {
          this.AddNsUB = data.data;
        }
        this.appObject.isLoading = false;
      }, error => {
        this.appObject.isLoading = false;
      });
    }

  }

  async getHomePromo() {
    this.appObject.isLoading = true;

    if (this.appObject.deviceUUID == "") {
      setTimeout(() => {
        this.getHomePromo();
      }, 100);
    } else {

      this.requestService.post('new-promotion/list', {
        'lang': this.appObject.currentLang,
        'device_id': this.appObject.deviceUUID,
        'device_type': config.plf,
        'restaurant_id': 'main'
      }).then(data => {
        if (data.status) {
          this.appObject.new_promotions = data.data;
        }
        this.appObject.isLoading = false;
      }, error => {
        this.appObject.isLoading = false;
      });
    }

  }

  isMobileWeb() {
    return this.platform.is('mobileweb');
  }

  /**
   * Fetch web app landing page meta details
   */
  getWebAppLandingPageMetaData() {
    return new Promise((resolve, reject) => {
      this.requestService.post('web-meta/restaurant/main', {
        'lang': this.appObject.currentLang,
        'device_id': this.appObject.deviceUUID,
        'device_type': config.plf,
        'restaurant_id': 'main'
      }).then(data => {
        return resolve(data);
      }, error => {
        this.appObject.isLoading = false;
        return reject(error);
      });
    })
  }

  async getDishesOfTheGivenCategory(cat_id) {
    return new Promise((resolve, reject) => {
      this.appObject.isLoading = true;

      this.activeMenu = cat_id;
      this.requestService.post('dish/list', {
        'device_id': this.appObject.deviceUUID,
        'language': this.appObject.currentLang,
        'device_type': config.plf,
        'menu_categorie_id': cat_id,
        'restaurant_id': this.appObject.selectedRestaurantDetails.id
      }).then(data => {
        this.appObject.isLoading = false;
        return resolve(data)
      }, error => {
        this.appObject.isLoading = false;
        return reject(error);
      });
    })
  }

  /**
   * Watching add to cart from cross selling page when toppings available on selected dish
   * @param data 
   */
  watchAddToCartFromCrossSelling(data) {
    this.addToCartSuccessfulyCallBack.next(data);
  }

  triggerAddToCartSuccssfulyCallback(): Subject<any> {
    return this.addToCartSuccessfulyCallBack;
  }

  /**
   * Display alergies and additives popup
   * @param dish dish object
   */
  async displayAlergies(dish) {
    let htmlContent = '';
    let translatedContent = '';
    // additives dataset
    if (dish && dish.additives && dish.additives.length) {
      await this.translate.get('key.additives').toPromise().then((response) => {
        if (response) translatedContent = response;
        else translatedContent = 'key.additives';
      })

      htmlContent += '<strong><p>' + translatedContent + '</p></strong><ul>'
      for (let i = 0; i < dish.additives.length; i++) {
        const additive = dish.additives[i];
        htmlContent += '<li>' + additive.letter + '<br>'
          + additive.description + ' </li>';
      }
      htmlContent += '</ul>'
    }
    // alagics dataset
    if (dish && dish.alagics && dish.alagics.length) {
      await this.translate.get('key.allergies').toPromise().then((response) => {
        if (response) translatedContent = response;
        else translatedContent = 'key.allergies';
      })

      htmlContent += '<strong><p>' + translatedContent + '</p></strong><ul>'
      for (let i = 0; i < dish.alagics.length; i++) {
        const alagic = dish.alagics[i];
        htmlContent += '<li>' + alagic.letter + '<br>'
          + alagic.description + ' </li>';
      }
      htmlContent += '</ul>'
    }

    // no data to display message
    // if (!htmlContent) {
    //   await this.translate.get('key.no_allergies_and_additives_data_to_display').toPromise().then((response) => {
    //     if (response) translatedContent = response;
    //     else translatedContent = 'key.no_allergies_and_additives_data_to_display';
    //   })
    //   htmlContent += '<span><strong>' + translatedContent + '</strong></span>'
    // }

    this.showAlert('alergy-info', 'Test', htmlContent, false);
  }

}
