import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { AppServiceService } from '../../../../api/app-service.service';
import { IonInput } from '@ionic/angular';
import consts from '../constatnts.json';

import { HttpClient } from '@angular/common/http';

declare var config: any;

@Component({
  selector: 'app-modal-popover',
  templateUrl: './modal-popover.page.html',
  styleUrls: ['./modal-popover.page.scss'],
})
export class ModalPopoverPage implements OnInit {
  @ViewChild('googleaddressweb', { read: IonInput }) googleaddress: IonInput;
  @ViewChild('crossSelling') crossSelling: any;

  POPOVER_TYPES = consts.POPOVER_TYPES;

  // This will keep records while navigate between components inside popover
  // Used to manage navigateBackward function
  historyStack: any[] = [];

  @Input() name: string;
  @Input() type: string;
  // always send the title as language key
  @Input() title: string;

  // when page click, slug need to be sent
  @Input() slug = null;

  @Input() isOrderProcessing: boolean = false;

  // Dish image preview url for DISH_IMAGE_PREVIEW
  @Input() dishImageURL = '';

  // cross selling component data set
  @Input() crossSellingProducts = [];
  @Input() selectedDishCategory;
  @Input() isFromCrossSelling: boolean;
  isInQuestionDishesPage: boolean = false;
  selectedQuestionCategory: any = null;

  // @Input() justSelectDeliveryAddress: boolean = false;

  // close popup after selecting delivery address
  // @Input() closeAfterSelection: boolean = false;

  isContentLoading: boolean = false;
  isRestaurantsAvailable: boolean = true;

  address = '';

  // payment id after placing order in checkout
  uniqueId: string = '';

  constructor(
    private modalCtr: ModalController,
    public appService: AppServiceService,
    private router: Router,
    private httpClient: HttpClient
  ) { }

  ngOnInit() { }

  ngAfterViewInit() {
    if (this.type === this.POPOVER_TYPES.LOCATION_SELECT.TYPE) {
      setTimeout(() => {
        this.appService.initGoogle(this.googleaddress);
      }, 1000);
    }
  }

  async close() {
    await this.modalCtr.dismiss();
  }

  /**
   * Will trigger when user click back btn from the view delivery address select
   */
  navigateBackward() {
    if (this.historyStack.length) {
      // get previous page and navigate
      this.navigateTo(this.historyStack[this.historyStack.length - 1], null, true);

      // remove the last record each time user navigate to previous page
      this.historyStack.splice(this.historyStack.length - 1, 1);
    }
  }

  /**
   * Navigate to another modal content or a page in web version
   * @param location the location to navigate
   * @param extraData extra data set if want to
   * @param isBackwardNavigating check wether user navigating backward (this one use to manage historyStack array)
   */
  navigateTo(location, extraData: any = null, isBackwardNavigating: boolean = false) {
    if (location === 'restaurant-list') {
      // clear error messages
      this.appService.appObject.errors = [];

      // clear my address data when user need all restaurants to select
      let getAllRestaurants = false;
      if (extraData === 'all') {
        getAllRestaurants = true;
      }
      // find restaurants
      this.appService.findNearByRestaurants(false, getAllRestaurants).then((response) => {

        // keep navigation history to navigate backward (just like in angular routing)
        if (getAllRestaurants) {
          this.historyStack.push(this.POPOVER_TYPES.DELIVERY_TYPE);
        } else {
          this.historyStack.push(this.POPOVER_TYPES.LOCATION_SELECT);
        }

        this.type = this.POPOVER_TYPES.RESTAURANT_SELECT.TYPE;
        // always send the title as language key
        this.title = this.POPOVER_TYPES.RESTAURANT_SELECT.TITLE;
      });
    } else if (location === 'menu-page') {
      if (extraData) {
        this.appService.getRestaurantBySlug(extraData.slug).then((response) => {
          this.router.navigate(['/menu']);
          this.close();
        });
      } else {
        this.router.navigate(['/menu']);
        this.close();
      }
    } else if (location && location.TYPE && location.TITLE) {
      // keep navigation history to navigate backward (just like in angular routing)
      // skip recording when user navigate backward with "isBackwardNavigating" condition
      // the second condition will prevent user go back to login form after login in to the user profile
      if (!isBackwardNavigating && !(location.TYPE === this.POPOVER_TYPES.USER_PROFILE.TYPE && this.type === this.POPOVER_TYPES.LOGIN.TYPE)) {
        Object.keys(this.POPOVER_TYPES).map((key) => {
          if (this.POPOVER_TYPES[key].TYPE === this.type) {
            this.historyStack.push(this.POPOVER_TYPES[key]);
          }
        });
      }

      // this will prevent user go back to registration form after successfuly registered and navigate in to the user profile
      // or user came to login page from checkout page and redirected into checkout page after logged in (prevent go back to login ui)
      // or user came to checkout page from login > register , and checkout, then prevent go back to registration page
      // or user came to order summary from checkout page and prevent go back to checkout page from summary page
      // or user came to delivery address select from login page prevent go back to login page
      if ((location.TYPE === this.POPOVER_TYPES.USER_PROFILE.TYPE && (this.type === this.POPOVER_TYPES.REGISTER.TYPE || this.type === this.POPOVER_TYPES.REGISTER_VIA.TYPE))
        || ((this.type === this.POPOVER_TYPES.LOGIN.TYPE
          || this.type === this.POPOVER_TYPES.REGISTER.TYPE
          || this.type === this.POPOVER_TYPES.REGISTER_VIA.TYPE)
          && location.TYPE === this.POPOVER_TYPES.CHECKOUT.TYPE)
        || this.type === this.POPOVER_TYPES.CHECKOUT.TYPE && location.TYPE === this.POPOVER_TYPES.ORDER_SUMMARY.TYPE
        || this.type === this.POPOVER_TYPES.LOGIN.TYPE && location.TYPE === this.POPOVER_TYPES.LOCATION_SELECT.TYPE) {
        this.historyStack = [];
      }

      this.type = location.TYPE;
      this.title = location.TITLE;

      // reset history stack if the current page is login, once user reset password successfuly navigate to login
      // login is a main page so no need to go back to password reset ui
      if (this.type === this.POPOVER_TYPES.LOGIN.TYPE) {
        this.historyStack = [];
      }

      // if next page is for select locations to find restaurants, then initialize google map service
      if (location.TYPE === this.POPOVER_TYPES.LOCATION_SELECT.TYPE) {
        if (navigator.geolocation && !this.appService.appObject.isAddressHave) {
          navigator.geolocation.getCurrentPosition((position) => {
            const longitude = position.coords.longitude;
            const latitude = position.coords.latitude;


            this.httpClient.get("https://maps.googleapis.com/maps/api/geocode/json?latlng=" + latitude + "," + longitude + "&sensor=true&key=" + config.gkey).toPromise().then((response: any) => {
              // console.log('response : ', response)
              if (response.status === 'OK') {
                let place = response.results[0];
                let addressValidating = this.appService.validateAddressComponents([response.results[0]]);
                if (this.appService.isAddressComponentValid(addressValidating)) {
                  this.appService.appObject.isAddressHave = true;
                  let address_splited = place.formatted_address.split(',');
                  this.address = place.formatted_address;
                  let country = address_splited[address_splited.length - 1];

                  if (country.trim() === 'Germany') {
                    this.address = this.address.replace(", Germany", "");
                  }

                  this.appService.appObject.myAddress.name = this.address;
                  // console.log(this.appService.appObject.myAddress.name);
                  this.appService.appObject.myAddress.lat = place.geometry.location.lat();
                  this.appService.appObject.myAddress.lng = place.geometry.location.lng();
                  this.appService.updateAppObject();
                  this.appService.appObject.errors = [];
                } else {
                  this.address = place.formatted_address;
                  this.appService.appObject.isAddressHave = false;
                  this.appService.appObject.myAddress.name = '';
                  this.appService.appObject.myAddress.lat = '';
                  this.appService.appObject.myAddress.lng = '';

                  this.appService.updateAppObject();
                  this.appService.appObject.errors['address'] = ['address_is_not_valid'];
                }
              }
            }).catch((error) => {
              console.log('google location error : ', error);
            });
          });
        } else {
          this.address = '';
        }

        setTimeout(() => {
          this.appService.initGoogle(this.googleaddress);
        }, 1000);
      }
    }
  }

  /**
   * This will pass as a callback into login component to trigger once user logged in
   * This also used to pass into registration component as a call back to get trigger once user successfuly registered and logged in
   * and return login operation response data set
   * @param loginResponse after login response object
   */
  async onLoginSuccess(loginResponse) {
    await this.appService.updateAppObject();
    if (this.appService.appObject.isOrderProcess) {
      if (this.appService?.appObject?.selectedDeliveryMethod === 'delivery' && (!this.appService.appObject.myAddress || !this.appService.appObject.myAddress.name
        || !this.appService.appObject.myAddress.lat || !this.appService.appObject.myAddress.lng)) {
        // this.justSelectDeliveryAddress = true;
        this.navigateTo(this.POPOVER_TYPES.LOCATION_SELECT);
      } else {
        // if user came to login page from checkout ui, then redirect into checkout page
        this.navigateTo(this.POPOVER_TYPES.CHECKOUT);
      }
      // make it false once user redirected to the checkout page
      this.appService.appObject.isOrderProcess = false;
    } else {
      this.navigateTo(this.POPOVER_TYPES.USER_PROFILE);
    }
    // console.log('loginResponse : ', loginResponse);
  }

  /**
   * Wll call back when user select create an account option from login page
   */
  onRegisterVia() {
    this.navigateTo(this.POPOVER_TYPES.REGISTER_VIA);
  }

  /**
   * This will call back when user select register with email option in register page
   */
  onRegisterViaEmail() {
    this.navigateTo(this.POPOVER_TYPES.REGISTER);
  }

  /**
   * This will call back when user choose forgot password option in login page
   */
  onForgotPassword() {
    this.navigateTo(this.POPOVER_TYPES.FORGOT_PASSWORD);
  }

  /**
   * This will call back when user reset password successfuly
   */
  onPasswordResetSuccess() {
    this.navigateTo(this.POPOVER_TYPES.LOGIN);
  }

  /**
   * This will navigate into dynamic pages slug is required
   * used for pnp and tnc
   * @param slug page slug
   */
  onNavigatePages(slug) {
    if (slug === 'close-modal') {
      this.close();
    } else {
      let pages = this.appService && this.appService.appObject && this.appService.appObject.settings && this.appService.appObject.settings.pages ? this.appService.appObject.settings.pages : [];
      let page = pages.find((page) => page.slug === slug);
      if (page) {
        this.slug = page.slug;
        this.navigateTo({ TYPE: this.POPOVER_TYPES.PAGES.TYPE, TITLE: page.name });
      }
    }
  }

  /**
   * On user change the delivery address from checkout page
   */
  onChangeDeliveryAddress() {
    this.navigateTo(this.POPOVER_TYPES.LOCATION_SELECT);
  }

  /**
   * Will trigger when user checkout as a guest user
   * @param paymentId payment id of the placed order
   */
  onOrderPlaced(paymentId, deliveryType) {
    if (paymentId) {
      this.uniqueId = paymentId;
      this.navigateTo({ TYPE: this.POPOVER_TYPES.ORDER_SUMMARY.TYPE, TITLE: 'key.' + deliveryType })
    }
  }

  /**
   * Will trigger when user select ordering type
   * @param orderType selected ordering type
   */
  selectOrderType(orderType) {
    this.appService.appObject.selectedDeliveryMethod = orderType;
    this.appService.updateAppObject();
    this.appService.calculateCartAmounts();
  }

  /**
   * Will trigger when user go to question page opening after adding item into cart
   * @param categoryData selected category data
   */
  openQuestionDishPage(categoryData) {
    if (categoryData) this.selectedQuestionCategory = categoryData;
  }

  /**
   * Will trigger when user click back button from cross selling dishes list page
   */
  navigateBackwardFromQuestionPage() {
    this.selectedQuestionCategory = null;
    this.crossSelling.navigateBackInWebVersion();
  }
}
