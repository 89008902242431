import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AppServiceService } from 'src/app/api/app-service.service';
import { CartPage } from '../cart/cart.page';

@Component({
  selector: 'app-toppings',
  templateUrl: './toppings.page.html',
  styleUrls: ['./toppings.page.scss'],
})
export class ToppingsPage implements OnInit {
  @Input() isFromCrossSelling: boolean;

  autoSelected: any = [];

  constructor(public modalController: ModalController, public appService: AppServiceService) {
    // console.log(this.appService.selectedDish);
    this.appService.appObject.errors = [];
    this.appService.hideCartButton = true;

  }

  ngOnInit() {
  }

  async dismiss() {
    // console.log(this.appService.hideCartButton);
    this.modalController.dismiss({
      'dismissed': true
    });

    if (this.appService.cartIsOpen) {
      const modal = await this.modalController.create({
        component: CartPage,
        cssClass: 'custom-popup'
      });
      await modal.present();
    } else {
      this.appService.hideCartButton = false;
    }
  }

  /**
   * Will return only active scenarios
   * @param scenarioList scenario list to filter from 
   * @returns filtered scenario list
   */
  getActiveScenarios(scenarioList) {
    if (scenarioList && scenarioList.length) {
      return scenarioList.filter((scenario) => scenario.status === 'true');
    } else {
      return [];
    }
  }

  /**
   * Will return status active topping list
   * @param toppingList topping list to filter from
   * @returns filtered topping list that status is active
   */
  getActiveToppings(toppingList) {
    if (toppingList && toppingList.length) {
      return toppingList.filter((topping) => {
        return topping.status === 'true'
      });
    } else {
      return [];
    }
  }

  autoSelect(topings, index, fun) {
    switch (fun) {
      case 'selectTopingOp':
        this.appService.selectTopingOp(null, index);
        break;

      default:
        break;
    }
    // console.log('auto', topings[0]['id']);
    return topings[0]['id'];
  }

  addFirstToping(id, index) {

    var ex = this.autoSelected.indexOf(id);
    if (ex == -1) {
      this.autoSelected.push(id);
      // console.log(id, index);
      this.appService.selectTopingOp({ detail: { value: id } }, index);
    }

  }

}
